<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ASSIGN OR INSTALL</h5>
                    <button type="button" class="close" @click="$emit('emitClose', 0)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="isLoading">
                        <div class="col-12"><Loader></Loader></div>
                    </div>
                    <form @submit.prevent="InstallApps" class="card-body text-center" v-else>
                        <div class="media">
                            <div class="media-body" v-if="app_id>0">
                                <img class="iconapp" alt="billing" v-if="app_id==1" src="@/core/assets/img/icon_billing.svg">
                                <img class="iconapp" alt="Website" v-else-if="app_id==2" src="@/core/assets/img/icon_sites.svg">
                                <img class="iconapp" alt="project" v-else-if="app_id==3" src="@/core/assets/img/icon_project.svg">
                                <img class="iconapp" alt="jobs" v-else-if="app_id==4" src="@/core/assets/img/icon_jobs.svg">
                                <img class="iconapp" alt="calculator" v-else-if="app_id==5"  src="@/core/assets/img/icon_calculator.svg">
                                <img class="iconapp" alt="Cars Calculator" v-else-if="app_id==6"  src="@/core/assets/img/icon_auxiliar.svg">
                                <img class="iconapp" alt="slabs" v-else-if="app_id==7"  src="@/core/assets/img/icon_slabs.svg">
                                <img class="iconapp" alt="bSite" v-else-if="app_id==8"  src="@/core/assets/img/icon_bsite.svg">
                                <img class="iconapp" alt="Gutters" v-else-if="app_id==9"  src="@/core/assets/img/icon_auxiliar.svg">
                                <img class="iconapp" alt="display" v-else-if="app_id==10"  src="@/core/assets/img/icon_display.svg">
                                <img class="iconapp" alt="dimenu" v-else-if="app_id==11"  src="@/core/assets/img/icon_dimenu.svg">
                                <img class="iconapp" alt="Marketea" v-else src="@/core/assets/img/icon_auxiliar.svg">
                                <h5 class="titulo_install">{{name}}</h5>
                                <p>{{description}} <a target="_blank" href="https://marketea.com/Terms-of-Use">Terms of Use.</a></p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-3"></div>
                            <div class="col-6">
                                <label>Select an application</label>
                                <!-- {{ listApps }} -->
                                <select class="form-control" v-model="app_id" @change="getDataApp()">
                                    <option value="0">--Choose App--</option>
                                    <option
                                        v-for="(i, index) in listApps"
                                        :key="index"
                                        :value="i.id"
                                    >{{i.name}}</option>
                                </select><br><br>
                                <button type="submit" class="btn btn-success" style="width: 100%;"
                                    :disabled="submit">
                                    INSTALL APP
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="row" v-if="messageError!=null">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                                {{ messageError }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/LoaderProgress'
export default {
	name: 'AssignAppsR',
    components: {
        Loader,
    },
	props: {
		dataApp: {
			type: [Object],
			default: () => {}
		}
	},
	data () {
		return {
            f: {
                resellerId: localStorage.getItem('resellerId'),
            },
			user_id: 0,
			org_id: 0,
			app_id: 0,
			name: '',
			description: '',
			listApps: [],
			submit: true,
            messageError: null,
            dataModal: null,
            isLoading: false,
            appListAssigned: null
		}
	},
	created() {
        this.dataModal = this.$store.getters['getDataModal'];
        console.log('dataModal', this.dataModal);
        if(this.dataModal.i && this.dataModal.i.user_id!=null && this.dataModal.i.user_id>0){
            this.user_id = this.dataModal.i.user_id;
            this.org_id = this.dataModal.i.id;
            this.appListAssigned = this.dataModal.appList;
            this.getListApps();
        }
	},
	methods: {
		getListApps(){
			let t = this;
			t.isLoading=true;
            let p = {
                url: 'r2/getAppslist/'+t.org_id,
                data: {org_id: t.org_id}
            };
            t.$store.dispatch('apiRest', p)
            .then((response) => {
                console.log(response);
                console.log('getAppslist', response)
                if(t.appListAssigned!=null && t.appListAssigned.length>0){
                    console.log('appListAssigned', t.appListAssigned)
                    t.listApps = response.r.filter(i => {
                        let valid= t.appListAssigned.filter(e => {
                            if(i.id==e.app_id){
                                return e;
                            }
                        });
                        console.log('valid', valid)
                        if(valid.length==0){
                            console.log('app add list', i)
                            return i;
                        }
                    });
                }else{
                    t.listApps = (response.r.length>0) ? response.r : [];
                }
                if(t.listApps==null || t.listApps.length==0){
                    t.submit=true;
                }else{t.submit=false;}
                // t.getDataApp();
                t.isLoading=false;
            })
            .catch((error) => {
                console.log(error);
				t.isLoading=false;
                t.messageError = error.message;
            });
		},
		getDataApp(){
			let t = this;
			t.name = null;
			t.description = null;
            if(t.app_id>0){
                t.listApps.map(function(item){
                    if(item.id == t.app_id){
                        t.name = item.name;
                        t.description = item.description;
                    }
                });
            }
		},
		InstallApps() {
			let t = this;
			t.submit=true;
			t.isLoading=true;
            let p = {
                url: 'r2/AssignAppFront/'+t.user_id,
                data: {
                    user_id : t.user_id,
                    org_id: t.org_id,
                    app_id: t.app_id,
                }
            };
            t.$store.dispatch('apiRest', p)
			.then((response) => {
                console.log('AssignAppFront', response);
				t.isLoading=false;
				t.submit=false;
				t.$emit('emitClose', 1);
			}).catch((error) => {
				console.log(error);
				t.submit=false;
				t.isLoading=false;
                t.messageError = error.message;
			});
		},
	}
};
</script>
<style lang="css" scoped>
h5.titulo_install{
	margin-top: 10px;
	font-weight:bold;
	font-size: 24px;
}
.media-body p {
	font-size: 12px;
	padding: 10px 60px;
}
.iconapp{
	width: 50px;
	height:auto;
}
</style>
