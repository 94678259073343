<template>
    <div class="container ">
        <div class="row">
                <div class="col-sm-12" v-if="isLoading">
                    <Loader></Loader>
                </div>
                <div class="col-xs-12 col-sm-12">
                    <infoApp></infoApp>
                </div>
        </div>
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/LoaderProgress'
    import infoApp from '@/core/components/Login/InfoApp'
    import { mapGetters } from 'vuex'
    export default {
        name: 'AuthLayout',
        components: {
            Loader,
            infoApp
        },
        data () {
            return {
                // urlPage: window.urlweb
            }
        },
        created () {
            this.validSesion();
        },
        methods: {
            validSesion(){
                let t = this;
                if(localStorage.getItem('resellerId')==null){
                    t.logout();
                }else{
                    let p = {
                        url: 'r1/sessionResellerLogin',
                        data: {
                            oauth_id: localStorage.getItem('oauth_id'),
                            user_id: localStorage.getItem('resellerId'),
                        }
                    };
                    t.$store.dispatch('apiRest', p)
                    .then(result => {
                        console.log(p.url, 'bien', result)
                        if(!result.tokenValid){
                            t.logout();
                        }else{
                            if(t.$store.getters['getLoggedIn']){
                                t.$router.push({ name: 'Dash'});
                            }else{
                                console.log('fallo validacion')
                                t.logout();
                            }
                        }
                    });
                }
            },
            logout() {
                console.log('disconnected');
                localStorage.setItem('validLogin', 1);
                localStorage.removeItem('token');
                localStorage.removeItem('oauth_id');
                localStorage.removeItem('resellerId');
                localStorage.removeItem('token_expires_at');
                localStorage.removeItem('dataUser');
                this.$router.push({ name: 'Login'});
            },
        },
        computed: {
            ...mapGetters({
                stateApi: 'getStateApi',
                loadingApi: 'getLoadingApi',
                loadingApiProgress: 'getLoadingApiProgress',
                apiResponse: 'getApiResponse',
                apiError: 'getApiError',
            }),
            isLoading: {
                get: function(){
                    return this.loadingApi;
                },
                set: function(v){
                    return v;
                },
            }
        },
        watch: {
            stateApi: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('stateApi', newVal);
                }
            },
            loadingApi: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('loadingApi', newVal);
                    this.isLoading = newVal;
                }
            },
            loadingApiProgress: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('loadingApiProgress', newVal);
                }
            },
            apiResponse: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('apiResponse', newVal);
                }
            },
            apiError: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('apiError', newVal);
                }
            },
        }
    };
</script>