<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 text-left">
        <h3 class="card-title">
          Organizations
        </h3>
        <p>
          <span v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1" class="filter" style="margin-right: 10px;">
            <!-- <span v-if="statusA == false" class="filter" @click="filterStatusC(1, 'a')"> -->
            <span v-if="statusA == false" class="filter">
              Actives: <span class="badge badge-success" style="margin-right: 10px">{{ contadorOrgsActives }}</span>
              <!-- <i class="far fa-square"></i> -->
            </span>
            <span v-else class="filter filtery" @click="filterStatusC(9999)">
              Actives: <span class="badge badge-success filterz">{{ contadorOrgsActives }}</span>
              <span class="badge filterx">
                X
              </span>
            </span>
          </span>
          <span v-else  style="margin-right: 10px">
            <span>
              Actives: <span class="badge badge-success" style="margin-right: 10px">{{ contadorOrgsActives }}</span>
              <!-- <i class="far fa-square"></i> -->
            </span>
          </span>
          <!-- <span v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1" class="filter"  style="margin-left: 10px">
            <span v-if="statusB == false" class="filter" @click="filterStatusC(0, 'b')">
              Deleted: <span class="badge badge-danger" style="margin-right: 10px">{{ contadorOrgsSuspended }}</span>
              <i class="far fa-square"></i>
            </span>
            <span v-else class="filter filtery" @click="filterStatusC(9999)">
              Deleted: <span class="badge badge-danger filterz">{{ contadorOrgsSuspended }}</span>
              <span class="badge filterx">
                X
              </span>
            </span>
          </span>
          <span v-else  style="margin-left: 10px">
            <span>
              Deleted: <span class="badge badge-danger filterz" style="margin-right: 10px">{{ contadorOrgsSuspended }}</span>
              <i class="far fa-square"></i>
            </span>
          </span> -->
        </p>
      </div>
      <div class="col-md-6">
        <h3 class="card-title">
          Search:
        </h3>
        <div class="row">
          <input type="text" class="form-control" v-model="TextSearch" placeholder="Name organizations"
            style="border: 1px solid #c9c9ca;" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body tdynamic">
        <div class="content-table-tdynamic">
          <table class="table-tdynamic" id="tableID">
            <thead>
              <tr>
                <th scope="col" class="colMore"><i class="far fa-plus-square"></i></th>
                <th scope="col" class="colId">#</th>
                <th class="colLogo">Logo</th>
                <th scope="col" class="colOrgInfo">Organization</th>
                <th scope="col" class="colAccInfo">Account</th>
                <th scope="col" class="colDates">Creation</th>
                <!-- <th scope="col" class="colDates">Last Activity</th> -->
                <th scope="col" class="colApps">Apps</th>
                <th scope="col" class="colStatus" v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1"
                  @click="filterStatus()" v-bind:class="[
                    'filter',
                    (resetstatusFilter && statusFilter == 0) ? 'filterOff' :
                      (!resetstatusFilter && statusFilter == 1) ? 'filterOn bg-success' :
                        (!resetstatusFilter && statusFilter == 0) ? 'filterOn bg-danger' : '',
                  ]" :style="(!resetstatusFilter && statusFilter == 0) ? 'color:#fff;' : ''">
                  Status Org
                </th>
                <th v-else scope="col" class="colStatus">
                  Status Org
                </th>
              </tr>
            </thead>
            <tbody v-if="isLoading === true">
              <tr>
                <th colspan="8" style="text-align: center">
                  <loader></loader>
                </th>
              </tr>
            </tbody>
            <tbody v-else-if="result.length === 0">
              <tr>
                <th colspan="8" style="text-align: center">
                  No record
                </th>
              </tr>
            </tbody>
            <tbody v-else>
              <template v-for="(i, index) in filtered" :key="index">
                <tr v-bind:class="['row-tr-' + index, (i.status == 2) ? 'hide' : '']">
                  <td class="colMore" @click="tMore(i, index)">
                    <i class="far fa-plus-square" v-if="i.moreOpen == 'hide'"></i>
                    <i class="far fa-minus-square" v-else></i>
                  </td>
                  <td class="colId">{{ i.id }}</td>
                  <td class="colLogo" @click="showModalLogo(i)" style="cursor:pointer;">
                    <span style="cursor: pointer;font-size: 50px;" v-if="i.logoCount == 0">
                      <i class="fas fa-image "></i>
                    </span>
                    <img v-bind:src="i.logoRuta" v-bind:class="['logo-org']" v-else />
                  </td>
                  <td class="colOrgInfo">
                    <b>{{ i.bill_company_name }}</b><br>
                    {{ i.bill_firstname }} {{ i.bill_lastname }}
                    <br v-if="i.bill_firstname || i.bill_lastname">
                    {{ i.bill_email }}
                  </td>
                  <td class="colAccInfo" @click="openEditBillInfo(i)" style="cursor:pointer;">
                    <span>
                      <b v-if="i.businnes != '' && i.businnes != 'null'">
                        {{ i.businnes }}
                      </b>
                      <br v-if="i.com_company_name != '' && i.com_company_name != 'null'">
                      <span v-if="i.active == 0" class="badge badge-danger">Blocked</span>
                      <span v-else-if="i.verifyEmail == 1"><i class="fa fa-lg fa-check-circle"
                          style="color:#5ac146;font-size: 10px;"></i></span>
                      <span v-else><i class="fa fa-lg fa-question-circle"
                          style="color:#f92e06;font-size: 10px;"></i></span>
                      #{{ i.user_id }}
                      <span v-if="i.name != '' && i.name != 'null'">{{ i.name + ' ' }}</span>
                      <span v-if="i.lastname != '' && i.lastname != 'null'">{{ i.lastname }}</span>
                      <br v-if="(i.name != '' && i.name != 'null') || (i.lastname != '' && i.lastname != 'null')">
                      {{ i.userEmail }} <br>
                      {{ i.userPhone }}
                    </span>
                  </td>
                  <td class="colDates">
                    <span>
                      {{ i.created_date }}<br>{{ i.created_time }}
                    </span>
                  </td>
                  <td class="colApps">
                    <span v-if="Object.keys(i.apps).length > 0">
                      <span v-for="(j, index) in i.apps" :key="index" class="badge badge-info"
                        style="margin-right: 5px;cursor:pointer;" @click="openModalaAssignApp(i)">
                        {{ j.name }}
                      </span>
                    </span>
                    <span v-else class="badge badge-danger" @click="openModalaAssignApp(i)"
                      style="cursor:pointer;">None</span>
                  </td>
                  <td class="colStatus">
                    <div class="action_btn_status">
                      <span @click="activeStatus(index)" v-bind:class="[
                        'statusSelected badge',
                        (i.status == 1) ? 'badge-success active' : '',
                        (i.status == 0) ? 'badge-danger inactive' : '',
                      ]" style="cursor: pointer">
                        <span v-if="i.status == 1">Active</span>
                        <span v-else>Deleted</span>
                        <!-- <i class="fas fa-angle-down"></i> -->
                      </span>
                      <span class="status_list" v-if="statusActive == index">
                        <ul class="reset" style="padding-left: 0; width: 70px;">
                          <li v-if="i.status == 0 || i.status == 2" style="width: 70px;margin-bottom: 10px;">
                            <a href="#" @click.prevent="activeOrg(i.user_id, 1, i.id, i.status)" v-bind:class="[
                              'badge', 'badge-success',
                            ]" style="display: block;text-align: left;font-size: 12px;">
                              Active
                            </a>
                          </li>
                          <li v-else style="width: 70px;margin-bottom: 10px;">
                            <a href="#" @click.prevent="activeOrg(i.user_id, 2, i.id, i.status)" v-bind:class="[
                              'badge', 'badge-danger cancelled',
                            ]" style="display: block;text-align: left;font-size: 12px;">
                              Deleted
                            </a>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr v-if="i.moreOpen == 'show'" class="moreDetails" :key="index + 'show'">
                  <td colspan="8" class="text-center">
                    <div class="row content-tr-extension">
                      <div class="col-md-6">
                        <h5 style="font-weight: bold;">List Users:</h5>
                        <table class="usersbyorg">
                          <thead>
                            <tr>
                              <th class="text-center">ID</th>
                              <th>Username</th>
                              <th>Name</th>
                              <th>Lastname</th>
                              <th class="text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody v-if="i.userbyOrgs.length > 0">
                            <template v-for="(usec, ukey) in i.userbyOrgs" :key="ukey">
                              <tr>
                                <td class="text-center">{{ usec.userSecID }}</td>
                                <td>{{ usec.userSecEmail }}</td>
                                <td>{{ usec.userSecFirstname }}</td>
                                <td>{{ usec.userSecLastnamename }}</td>
                                <td class="text-center">
                                  <span v-if="usec.userSecStatus == 0" class="badge badge-danger">Inactive</span>
                                  <span v-if="usec.userSecStatus == 1" class="badge badge-success">Sctive</span>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" class="text-center">No record.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <component :is="nameCompModal" v-if="isShowModal" @emitClose="getEmitcloseModal($event)"></component>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import EditBillInfo from '@/core/components/AccM/EditBillInfo'
import EditLogoOrg from '@/core/components/OrgM/EditLogoOrg'
import Loader from '@/core/components/shared/LoaderProgress'
import AssigApp from '@/core/components/OrgM/AssigApp'
export default {
  name: 'OrgbyReseller',
  components: {
    Loader,
    AssigApp,
    EditLogoOrg,
    EditBillInfo,
  },
  data() {
    return {
      isLoading: false,
      result: [],
      OrgLists: [],
      f: {
        resellerId: localStorage.getItem('resellerId'),
      },
      statusActive: null,
      statuslist: [],
      activeModalOrgs: false,
      listPay: [],
      statusPayActive: null,
      contadorOrgsActives: 0,
      contadorOrgsSuspended: 0,
      contadorStandar: 0,
      contadorPremium: 0,
      contadorPaid: 0,
      contadorUnpaid: 0,
      contadorCancelled: 0,
      m: {
        show: false,
        component: '',
        data: []
      },
      TextSearch: '',
      oldList: [],
      filterList: [],
      planA: false,
      planB: false,
      billingA: false,
      billingB: false,
      statusA: false,
      statusB: false,
      planFilter: null,
      statusFilter: null,
      billingFilter: null,
      resetplanFilter: true,
      resetstatusFilter: true,
      resetbillingFilter: true,
      listCancelled: false,
      ruta: '',
      logoEmpty: '',
      isShowModal: false,
      nameCompModal: null,
    }
  },
  created() {
    console.log('OrgbyReseller')
    this.getOrg();
  },
  methods: {
    filterAlpha(type) {
      let t = this;
      let listNew = [];
      for (let e in t.filterList) {
        if (t.filterList[e]) {
          let insert = false;
          // se filtra por status --principal--
          if (type == 'status') {
            // start ==== status contador
            if (t.statusFilter == null && t.planFilter == null && t.billingFilter == null) {
              insert = true;
            }
            if (t.statusFilter == null && t.planFilter == null && parseInt(t.billingFilter) >= 0) {
              if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }
            }
            if (t.statusFilter == null && parseInt(t.planFilter) >= 0 && t.billingFilter == null) {
              if (parseInt(t.planFilter) == 1) {
                if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
              }
              if (parseInt(t.planFilter) == 2) {
                if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
              }
            }
            if (t.statusFilter == null && parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0) {
              if (parseInt(t.planFilter) == 1) {
                if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay) && parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }
              }
              if (parseInt(t.planFilter) == 2) {
                if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay) && (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }
              }
            }
            // end ==== status contador
            // start ==== status
            if (t.billingFilter == null && t.planFilter == null) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
            }
            // end ==== status
            // start ==== billing > status
            if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) {
              // billing > status
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0 && t.planFilter == null) {
                if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter)) { insert = true; }
              }
              // billing < status
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) == 2 && t.planFilter == null) {
                if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
              }
              // billing > plan > status
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) <= 1) {
                if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].status) == parseInt(t.statusFilter)) { insert = true; }
              }
              // billing > plan < status
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) == 2) {
                if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }
              }
            }
            // end ==== status > status
            // start ==== plan > status
            if (parseInt(t.planFilter) == 1) {
              if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) {
                // plan > status
                if (parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) <= 2 && t.billingFilter == null) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter)) { insert = true; }
                }
                // plan < status
                if (parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) == 2 && t.billingFilter == null) {
                  if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
                }
                // plan > billing > status
                if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
            }
            if (parseInt(t.planFilter) == 2) {
              if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) {
                // plan > status
                if (parseInt(t.statusFilter) <= 2 && t.billingFilter == null) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter)) { insert = true; }
                }
                // plan < status
                if (parseInt(t.statusFilter) == 2 && t.billingFilter == null) {
                  if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
                }
                // plan > billing > status
                if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
            }
            // end ==== plan > billing
          }
          if (type == 'billing') {
            // start ==== billing contador
            if (t.billingFilter == null && t.statusFilter == null && t.planFilter == null) {
              insert = true;
            }
            if (t.billingFilter == null && t.statusFilter == null && parseInt(t.planFilter) >= 0) {
              if (parseInt(t.planFilter) == 1) {
                if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
              }
              if (parseInt(t.planFilter) == 2) {
                if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
              }
            }
            if (t.billingFilter == null && parseInt(t.statusFilter) >= 0 && t.planFilter == null) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
            }
            if (t.billingFilter == null && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status) && (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }
            }
            // end ==== billing contador
            // start ==== billing
            if (t.planFilter == null && t.statusFilter == null) {
              if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }
            }
            // end ==== billing
            // start ==== status > billing
            if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) {
              // status > billing
              if (parseInt(t.billingFilter) >= 0 && t.planFilter == null) {
                if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
              }
              // status > billing > plan
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0) {
                if (parseInt(t.planFilter) == 1) {
                  if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
                if (parseInt(t.planFilter) == 2) {
                  if ((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
              // status > plan < billing
              if (parseInt(t.billingFilter) == 2 && parseInt(t.planFilter) >= 0) {
                if (parseInt(t.planFilter) == 1) {
                  if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }
                }
                if (parseInt(t.planFilter) == 2) {
                  if ((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }
                }
              }
            }
            // se filtra primero por status para restablecer, punto clave [null || 2, 3] --terciario--
            if (parseInt(t.statusFilter) >= 0 && (t.planFilter == null || parseInt(t.planFilter) == 3) && (t.billingFilter == null || parseInt(t.billingFilter) == 2)) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
            }
            // end ==== status > billing
            // start ==== plan > billing
            if (parseInt(t.planFilter) == 1) {
              if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) {
                // status > billing
                if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) <= 2 && t.statusFilter == null) {
                  if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
                // status < billing
                if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) == 2 && t.statusFilter == null) {
                  if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
                }
                // plan > status > billing
                if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
            }
            if (parseInt(t.planFilter) == 2) {
              if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) {
                // status > billing
                if (parseInt(t.billingFilter) <= 2 && t.statusFilter == null) {
                  if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
                // status < billing
                if (parseInt(t.billingFilter) == 2 && t.statusFilter == null) {
                  if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
                }
                // plan > status > billing
                if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
                  if (parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
            }
            // end ==== plan > billing
          }
          if (type == 'plan') {
            // start ==== plan contador
            if (t.planFilter == null && t.statusFilter == null && t.billingFilter == null) {
              insert = true;
            }
            if (t.planFilter == null && t.statusFilter == null && parseInt(t.billingFilter) >= 0) {
              if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }
            }
            if (t.planFilter == null && parseInt(t.statusFilter) >= 0 && t.billingFilter == null) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
            }
            if (t.planFilter == null && parseInt(t.statusFilter) >= 0 && parseInt(t.billingFilter) >= 0) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
            }
            // end ==== plan contador
            // end ==== plan
            if (t.billingFilter == null && t.statusFilter == null) {
              // if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
              if (parseInt(t.planFilter) == 1) {
                if (parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
              }
              if (parseInt(t.planFilter) == 2) {
                if (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
              }
            }
            // end ==== plan
            // start ==== status < plan
            // se filtra primero por status para restablecer, punto clave [null || 2, 3] --terciario--
            if (parseInt(t.statusFilter) >= 0 && (t.planFilter == null || parseInt(t.planFilter) == 3) && (t.billingFilter == null || parseInt(t.billingFilter) == 2)) {
              if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
            }
            // end ==== status < plan
            // start ==== status > billing > plan
            if (parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) {
              // status > plan
              if (t.billingFilter == null && parseInt(t.planFilter) >= 0) {
                if (parseInt(t.planFilter) == 1) {
                  if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }
                }
                if (parseInt(t.planFilter) == 2) {
                  if ((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }
                }
              }
              // status > billing > plan
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0) {
                if (parseInt(t.planFilter) == 1) {
                  if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
                if (parseInt(t.planFilter) == 2) {
                  if ((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
                }
              }
              // status > billing < plan
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) == 3) {
                if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
              }
            }
            // end ==== status > billing > plan
            // start ==== billing > plan
            if (parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) {
              // billing > status
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) <= 2 && t.statusFilter == null) {
                if (parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }
              }
              // billing < plan
              if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) == 3 && t.statusFilter == null) {
                if (parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }
              }
            }
            // end ==== status > plan
          }
          if (insert) {
            listNew.push(t.filterList[e]);// insertamos fila a la nueva lista
          }
        }
      }
      return listNew
    },
    filterStatusC(filter, type) {
      if (filter < 9999) {
        this.statusFilter = filter;
        this.resetstatusFilter = false;
        this.filterStatus(filter);
        if (type == 'a') {
          this.statusA = true
          this.statusB = false
        } else {
          this.statusB = true
          this.statusA = false
        }
      } else {
        this.statusA = false
        this.statusB = false
        this.statusFilter = null
        this.filterStatus(4);
      }
    },
    filterStatus(filter) {
      let t = this;
      // guardamos copia de la lista original
      if (filter == undefined) {
        // sino existe filter
        if (t.resetstatusFilter) {
          t.statusFilter = 0;
        } else {
          t.statusFilter++; // incrementamos valor para proximo filtro
        }
      }
      let listNew = this.filterAlpha('status')
      if (listNew.length > 0) {
        t.result = listNew; // retornamos nueva lista con item filtrados
        t.resetstatusFilter = false;
      }
      let ctl
      if (parseInt(t.statusFilter) >= 2 && t.billingFilter == null && t.planFilter == null) { ctl = true; }
      if (parseInt(t.statusFilter) >= 2 && (parseInt(t.billingFilter) >= 0 || parseInt(t.planFilter) >= 0)) { ctl = false; }
      if (ctl == false) {
        t.statusFilter = null; // reiniciamos al llegar al maximo
        t.resetstatusFilter = true; // activamos reset
      }
      if (ctl) {
        t.statusFilter = null; // reiniciamos al llegar al maximo
        t.resetstatusFilter = true; // activamos reset
        t.result = t.filterList; // restauramos lista sin filter
      }
      if (t.statusFilter == 1) {
        t.statusA = true
        t.statusB = false
      } else if (t.statusFilter == 0) {
        t.statusA = false
        t.statusB = true
      } else {
        t.statusA = false
        t.statusB = false
      }
    },
    filterPlanStatus(filter, type) {
      if (filter < 9999) {
        this.planFilter = filter;
        this.resetplanFilter = false;
        this.filterPlan(filter);
        if (type == 'a') {
          this.planA = true
          this.planB = false
        } else {
          this.planB = true
          this.planA = false
        }
      } else {
        this.planA = false
        this.planB = false
        this.planFilter = null
        this.filterPlan(4);
      }
    },
    filterPlan(filter) {
      let t = this;
      // guardamos copia de la lista original
      if (filter == undefined) {
        // sino existe filter
        if (t.resetplanFilter) {
          t.planFilter = 1;
        } else {
          t.planFilter++; // incrementamos valor para proximo filtro
        }
      }
      let listNew = this.filterAlpha('plan')
      if (listNew.length > 0) {
        t.result = listNew; // retornamos nueva lista con item filtrados
        t.resetplanFilter = false;
      }
      let ctl
      if (parseInt(t.planFilter) >= 3 && t.billingFilter == null && t.statusFilter == null) { ctl = true; }
      if (parseInt(t.planFilter) >= 3 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) { ctl = false; }
      if (parseInt(t.planFilter) >= 3 && t.billingFilter == null && parseInt(t.statusFilter) >= 0) { ctl = false; }
      if (parseInt(t.planFilter) >= 3 && parseInt(t.billingFilter) >= 0 && t.statusFilter == null) { ctl = false; }
      if (ctl == false) {
        t.planFilter = null; // reiniciamos al llegar al maximo
        t.resetplanFilter = true; // activamos reset
      }
      if (ctl) {
        t.planFilter = null; // reiniciamos al llegar al maximo
        t.resetplanFilter = true; // activamos reset
        t.result = t.filterList; // restauramos lista sin filter
      }
      if (t.planFilter == 1) {
        t.planA = true
        t.planB = false
      } else if (t.planFilter == 2) {
        t.planA = false
        t.planB = true
      } else {
        t.planA = false
        t.planB = false
      }
    },
    filterBillingStatus(filter, type) {
      if (filter < 9999) {
        this.billingFilter = filter;
        this.resetbillingFilter = false;
        this.filterBilling(filter);
        if (type == 'a') {
          this.billingA = true
          this.billingB = false
        } else {
          this.billingB = true
          this.billingA = false
        }
      } else {
        this.billingA = false
        this.billingB = false
        this.billingFilter = null
        this.filterBilling(3);
      }
    },
    filterBilling(filter) {
      let t = this;
      // guardamos copia de la lista original
      if (filter == undefined) {
        // sino existe filter
        if (t.resetbillingFilter) {
          t.billingFilter = 0;
        } else {
          t.billingFilter++; // incrementamos valor para proximo filtro
        }
      }
      let listNew = this.filterAlpha('billing')
      if (listNew.length > 0) {
        t.result = listNew; // retornamos nueva lista con item filtrados
        t.resetbillingFilter = false;
      }
      let ctl
      if (parseInt(t.billingFilter) >= 2 && t.planFilter == null && t.statusFilter == null) { ctl = true }
      if (parseInt(t.billingFilter) >= 2 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) >= 0) { ctl = false }
      if (parseInt(t.billingFilter) >= 2 && t.planFilter == null && parseInt(t.statusFilter) >= 0) { ctl = false }
      if (parseInt(t.billingFilter) >= 2 && parseInt(t.planFilter) >= 0 && t.statusFilter == null) { ctl = false }
      if (ctl == false) {
        t.billingFilter = null; // reiniciamos al llegar al maximo
        t.resetbillingFilter = true; // activamos reset
      }
      if (ctl) {
        t.billingFilter = null; // reiniciamos al llegar al maximo
        t.resetbillingFilter = true; // activamos reset
        t.result = t.filterList; // restauramos lista sin filter
      }
      if (t.billingFilter == 1) {
        t.billingA = true
        t.billingB = false
      } else if (t.billingFilter == 0) {
        t.billingA = false
        t.billingB = true
      } else {
        t.billingA = false
        t.billingB = false
      }
    },
    tMore(i, index) {
      console.log('tMore', i, index)
      let t = this;
      t.oldList = t.result;
      let listNew = [];
      for (let e in t.oldList) {
        if (t.oldList[e]) {
          if (i.id == t.oldList[e].id) {
            if (t.oldList[e].moreOpen == 'show') {
              t.oldList[e].moreOpen = 'hide';
            }
            else {
              t.oldList[e].moreOpen = 'show';
            }
            console.log(i.moreOpen)
          }
        }
        listNew.push(t.oldList[e]);
      }
      t.result = listNew;
    },
    activeStatus(index) {
      if (this.statusActive == index) {
        this.statusActive = null;
      } else {
        this.statusActive = index;
      }
    },
    activeOrg(user_id, active, id, old_status) {
			let msj = "Want to suspend organization?";
			if(active == 1) {
				msj = "You want to activate organization?";
			} else if(active == 2) {
				msj = "You want to cancelled organization?";
			}	
			if(confirm(msj)) {
				this.activeOrgAction(user_id, active, id, old_status);
			}
		},
		activeOrgAction(user_id, active, id, old_status) {
      let t = this;
      t.statusActive = null;
      let p = {
        url: 'r2/changeStatusOrgs',
        data: {
				user_id: user_id,
				active:active,
				id:id,
				old_status:old_status,
        }
      };
      t.$store.dispatch('apiRest', p)
			.then((response) => {
				t.isLoading = false;
				console.log('activeOrg', response);
				t.getOrg();
			})
			.catch((error) => {
				t.isLoading = false;
				console.log(error);
			});
		},
    checkSubmenu(e) {
      let submenus = document.querySelectorAll('.' + e.target.classList[0]);
      submenus.forEach(function (i) {
        if (i != e.target) {
          i.checked = false;
        }
      });
    },
    modalaResOrgs(component, i) {
      this.m = {
        show: true,
        component: component,
        data: i
      };
      this.activeModalOrgs = true;
      // this.$store.commit('core/setOpenModal', this.m);
    },
    getOrg() {
      let t = this;
      t.isLoading = true
      t.contadorOrgsActives = 0;
      t.contadorOrgsSuspended = 0;
      t.contadorStandar = 0;
      t.contadorPremium = 0;
      t.contadorPaid = 0;
      t.contadorUnpaid = 0;
      t.contadorCancelled = 0;
      let p = {
        url: 'r2/getOrg',
        data: t.f
      };
      t.$store.dispatch('apiRest', p)
        .then((response) => {
          t.statuslist = response.statusList;
          let u = response.r;
          t.listPay = response.statusPayList;
          t.logoEmpty = response.logoEmpty;
          t.result = u;
          let o = []
          for (let i in u) {
            if (u[i].statusPay != 2) {
              t.result[i].moreOpen = 'hide';
              t.result[i].rowNormal = true;
              if (u[i]) {
                if (u[i].status == 1) { t.contadorOrgsActives += 1; }
                if (u[i].status == 0 || u[i].status == 2) { t.contadorOrgsSuspended += 1; }
              }
              let apps = u[i].apps
              let p = [{ app_id: 1 }, { app_id: 8 }, { app_id: 4 }, { app_id: 10 },
              { app_id: 11 }, { app_id: 2 }, { app_id: 7 }, { app_id: 3 }, { app_id: 9 }]
              let g = []
              for (let l in p) {
                for (let j in apps) {
                  if (apps[j].app_id === p[l].app_id) {
                    g.push(apps[j])
                  }
                }
              }
              u[i].logo = u[i].logo || '';
              u[i].logoCount = u[i].logoCount || '';
              u[i].logoRuta = u[i].logoRuta || '';
              u[i].apps = g;
              o.push(u[i])
            }
          }
          console.log(t.contadorUnpaid);
          t.result = o;
          let k = t.result
          let p = t.sortedKeyValue(k, 'id')
          t.result = []
          t.result = p
          t.filterList = p
          t.isLoading = false
          //let objIternos= ['info', 'apps', 'userbyOrgs'];
          //let resulCombos= []
          //t.combinaciones(t.result, 3, objIternos, resulCombos);
        })
        .catch((error) => {
          console.log(error);
          t.isLoading = false
        });
    },
    sortedKeyValue(obj, key) {
      return obj.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        return ((y < x) ? -1 : ((y > x) ? 1 : 0)); // ordenar de mayor a menor
        // return ((x < y) ? -1 : ((x > y) ? 1 : 0)); // ordenar de menor a mayor
      });
    },
    combinaciones(arr, n, objIternos, resultados, combinados) {
      if (!combinados) {
        combinados = [];
      }
      console.log('correr combinaciones')
      arr.filter(function (obj) {
        console.log('item combo', obj)
        // buscar y separar objetos internos
        for (let i in obj) {
          if (objIternos.indexOf(i) > -1) {
            console.log('obj interno encontrado', i, obj[i])
            let iterno = obj[i];
            for (let j in iterno) {
              obj[i + '_' + j] = iterno[j];
            }
            obj.splice(i);
          }
        }
        // let combos = '';
        // for(let i=0; i<item.length; ++i) {
        // let newResultado = combinados.slice();
        // let newCombo = item.slice();
        // newResultado.push(newCombo[i]);
        // //combos+=arr[i];
        // console.log('combos', newResultado)
        // newCombo.splice(i, 1);
        // if(n>1) {
        // comb(newCombo, n-1, resultados, newResultado);
        // } else {
        // resultados.push(newResultado);
        // }
        // }
      });
    },
    closeModal() {
      this.isShowModal = false;
      this.nameCompModal = null;
      this.$store.commit('setDataModal', null);
    },
    showModal() {
      this.isShowModal = true;
    },
    getEmitcloseModal(value) {
      console.log('getEmitcloseModal', value);
      this.closeModal();
      if (value == 1) {
        this.getOrg();
      }
    },
    openModalaAssignApp(i) {
      this.$store.commit('setDataModal', { i: i, appList: i.apps });
      this.nameCompModal = "AssigApp";
      this.showModal();
    },
    openEditBillInfo(i) {
      this.$store.commit('setDataModal', i);
      this.nameCompModal = "EditBillInfo";
      this.showModal()
    },
    showModalLogo(i) {
      this.$store.commit('setDataModal', { i: i, logoEmpty: this.logoEmpty});
      this.nameCompModal = "EditLogoOrg";
      this.showModal()
    },
  },
  computed: {
    filtered: function () {
      var t = this;
      if (t.TextSearch.length == 0) {
        return t.result;
      } else {
        return t.result.filter(function (item) {
          let fullname = item.bill_firstname + ' ' + item.bill_lastname
          let fullnameA = item.bill_firstname + ' ' + item.bill_company_name
          let fullnameB = item.bill_lastname + ' ' + item.bill_firstname
          let fullnameC = item.bill_lastname + ' ' + item.bill_company_name
          let fullnameD = item.bill_company_name + ' ' + item.bill_firstname
          let fullnameE = item.bill_company_name + ' ' + item.bill_lastname
          let fullnameF = item.bill_firstname + ' ' + item.com_company_name
          let fullnameG = item.bill_lastname + ' ' + item.com_company_name
          let fullnameH = item.com_company_name + ' ' + item.bill_firstname
          let fullnameI = item.com_company_name + ' ' + item.bill_lastname
          let fullnameJ = item.bill_firstname + ' ' + item.bill_lastname + ' ' + item.bill_company_name
          let fullnameK = item.bill_lastname + ' ' + item.bill_firstname + ' ' + item.bill_company_name
          let fullnameM = item.bill_company_name + ' ' + item.bill_lastname + ' ' + item.bill_firstname
          let fullnameN = item.bill_company_name + ' ' + item.bill_firstname + ' ' + item.bill_lastname
          let fullnameO = item.bill_firstname + ' ' + item.bill_lastname + ' ' + item.com_company_name
          let fullnameP = item.bill_lastname + ' ' + item.bill_firstname + ' ' + item.com_company_name
          let fullnameQ = item.com_company_name + ' ' + item.bill_lastname + ' ' + item.bill_firstname
          let fullnameR = item.com_company_name + ' ' + item.bill_firstname + ' ' + item.bill_lastname
          return (
            (item.bill_lastname &&
              item.bill_lastname
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.bill_firstname &&
              item.bill_firstname
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.bill_company_name &&
              item.bill_company_name
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.com_company_name &&
              item.com_company_name
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullname &&
              fullname
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameA &&
              fullnameA
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameB &&
              fullnameB
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameC &&
              fullnameC
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameD &&
              fullnameD
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameE &&
              fullnameE
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameF &&
              fullnameF
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameG &&
              fullnameG
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameH &&
              fullnameH
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameI &&
              fullnameI
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameJ &&
              fullnameJ
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameK &&
              fullnameK
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameM &&
              fullnameM
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameN &&
              fullnameN
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameO &&
              fullnameO
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameP &&
              fullnameP
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameQ &&
              fullnameQ
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameR &&
              fullnameR
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.userPhone &&
              item.userPhone
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.userEmail &&
              item.userEmail
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0)
          );
        });
      }
    },
    // ...mapGetters("core", { getOpenModal: "getOpenModal" }),
  },
  watch: {
  },
}
</script>
<style lang="scss" scoped>
  @import '../assets/css/tdynamic.css';
  .tdynamic {
    height: calc(100vh - 180px);
  }
  .tdynamic .content-table-tdynamic {
    top: 0px;
  }
  .table-tdynamic thead tr th {
    font-weight: bold;
  }
  .table-tdynamic thead tr th,
  .table-tdynamic tbody tr td {
    padding: 1px 8px;
  }
  .table-tdynamic thead tr th.colMore,
  .table-tdynamic tbody tr td.colMore {
    width: 30px;
  }
  .table-tdynamic tbody tr td.colMore:hover {
    cursor: pointer;
  }
  .table-tdynamic thead tr th.colId,
  .table-tdynamic tbody tr td.colId {
    width: 40px;
  }
  .table-tdynamic thead tr th.colLogo,
  .table-tdynamic tbody tr td.colLogo {
    text-align: center;
    width: 80px;
  }
  .table-tdynamic tbody tr td.colLogo img.logo-org {
    height: auto;
    width: 100%;
    display: block;
  }
  .table-tdynamic thead tr th.colOrgInfo,
  .table-tdynamic tbody tr td.colOrgInfo {
    width: 220px;
    text-align: left;
  }
  .table-tdynamic thead tr th.colAccInfo,
  .table-tdynamic tbody tr td.colAccInfo {
    width: 220px;
    text-align: left;
  }
  .table-tdynamic thead tr th.colApps,
  .table-tdynamic tbody tr td.colApps {
    width: 200px;
  }
  .table-tdynamic thead tr th.colActions,
  .table-tdynamic tbody tr td.colActions {
    width: 80px;
  }
  .table-tdynamic thead tr th.colStatus,
  .table-tdynamic tbody tr td.colStatus {
    width: 80px;
  }
  .table-tdynamic thead tr th.colDates,
  .table-tdynamic tbody tr td.colDates {
    width: 130px;
  }
  .status_list {
    padding: 0px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    /* width: 120px; */
    font-size: 14px;
    z-index: 9;
  }
  .status_list>ul {
    margin-bottom: 0px;
    border: 1px solid #ccc;
  }
  .status_list>ul>li {
    display: block;
    position: relative;
    margin: 1px 0;
    padding: 5px;
  }
  .status_list>ul>li:hover {
    background-color: #efefef;
    cursor: pointer;
  }
  .action_btn_status {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
  }
  span.statusSelected {
    font-size: 12px;
  }
  span.statusSelected i {
    color: #000;
  }
  .badgeOro {
    background-color: #f1c94c;
    color: #606060;
  }
  .tableRow {
    width: 100%;
    border: 0px solid;
    border-collapse: initial;
    border-bottom: 0px solid #eee;
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
    display: table;
    text-indent: initial;
    border-spacing: 0px;
    border-color: grey;
  }
  .cdropdownx>a:before,
  .cdropdownx>button:before {
    position: absolute;
    right: 7px;
    top: 15px;
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  .dropx {
    background-color: transparent !important;
    border: 0px solid #ccc !important;
    color: white !important;
    padding: 0px 15px 0px 0px !important;
  }
  .usersbyorg {
    width: 100%;
    border: 1px solid #ccc;
    margin: 10px 0px;
  }
  .usersbyorg thead tr th {
    border-top: 1px solid #ccc;
  }
  .content-tr-extension {
    background-color: #fff !important;
    margin-left: 0px;
    margin-right: 0px;
  }
  .filter:hover {
    cursor: pointer;
  }
  .filterOff {}
  .filterOn::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .filterOn.bg-danger.unpaid,
  .filterOn.bg-success.paid,
  .filterOn.bg-danger.cancelled {
    color: #fff;
  }
  .filterOn.bg-warning.declined {
    color: #000;
  }
  .filterOn.bg-secondary.unpaid {
    color: #fff;
    background-color: #6c757d;
  }
  .filterOn.bg-warning.primium {
    background-color: #f1c94c;
    color: #606060;
  }
  .filterx {
    cursor: pointer;
    border-left: 1px solid rgb(211, 217, 217);
    border-radius: 0px !important;
    padding: 1px !important;
    font-size: inherit !important;
  }
  .filtery {
    border: 1px solid rgb(211, 217, 217);
    padding: 0.1px;
    background-color: #fff;
  }
  .filterz {
    border-radius: 0px !important;
    padding: 0.4px 3px !important;
    font-size: inherit !important;
  }
</style>
