<template>
  <div class="card">
    <div class="card-header text-center">
      <h4>Reseller - Panel</h4>
    </div>
    <form @submit.prevent="loginReseller()">
      <div class=" card-body">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="form-row">
              <div class="form-group col-md-12">
                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                  {{ messageError }}
                </div>
                <!-- <label for="email">E-mail</label> -->
                <div class="password-input">
                  <span class="showpassord btn btn-sm btn-info "
                    style="width: 100px;display: flex;justify-content: space-around;font-size: 14px;border-color: #000cf7;background-color: #000cf7;"
                    @click="setSms()">
                    <i class="fas fa-paper-plane"></i> Get Code
                  </span>
                  <input type="number" class="form-control" v-model="userCredenctial.phone" maxlength="15"
                    placeholder="Number phone" id="phone" autocomplete="tel" required />
                </div>
              </div>
              <div class="form-group col-md-12" style="margin-bottom: 0px;">
                <!-- <label for="password">Password</label> -->
                <div class="password-input">
                  <span class="showpassord icon-eye" action="hide">
                    <i class="fas fa-shield-alt"></i>
                  </span>
                  <input id="password" type="number" :class="['form-control', (formLogin) ? 'disabled' : '']"
                    v-model="userCredenctial.codeSMS" name="password" placeholder="Code SMS: 0 0 0 0" autocomplete="no"
                    required :disabled="formLogin" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 note text-left" style="margin-top: 10px;">
            <label style="display: flex;justify-content: space-between;width: 160px;">
              I already have a code
              <input type="checkbox" @click="formLogin = !formLogin" :checked="(formLogin == false)" class="form-control"
                style="height: 20px;line-height: 1;display: inline-block;width: 20px;" />
            </label>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div v-if="!isLoading">
          <button type="submit" :disabled="formLogin" class="btn btn-success btn-login">Sign In</button>
        </div>
        <div v-else>
          <Loader></Loader>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
import { mapGetters } from 'vuex'
export default {
  name: 'FormLogin',
  components: {
    Loader,
  },
  data() {
    return {
      formLogin: true,
      messageError: null,
      userCredenctial: {
        email: '',
        phone: '',
        password: '',
        codeSMS: '',
        typeU: 'client'
      },
    }
  },
  created() {
  },
  methods: {
    setSms() {
      let t = this;
      t.messageError = null;
      let p = {
        url: 'r1/setSMSLogin',
        data: t.userCredenctial,
      };
      t.$store.dispatch('apiRest', p)
        .then(result => {
          console.log(p.url, 'bien', result)
          if (result.status) {
            t.formLogin = false;
          } else {
            t.userCredenctial.phone = null;
            t.messageError = result.msj;
            t.formLogin = true;
          }
        }).catch((err) => {
          console.log(p.url, 'Error', err)
          t.userCredenctial.phone = null;
          t.messageError = err;
          t.formLogin = true;
        });
    },
    loginReseller() {
      let t = this;
      t.messageError = null;
      t.userCredenctial.password = t.userCredenctial.codeSMS;
      let p = {
        url: 'r1/loginReseller',
        data: t.userCredenctial,
      };
      t.$store.dispatch('apiRest', p)
        .then(result => {
          console.log(p.url, 'bien', result)
          t.$store.commit('setLogin', result);
          if (t.$store.getters['getLoggedIn']) {
            window.location.href = window.location.origin;
          } else {
            t.messageError = result.msj;
            t.userCredenctial.codeSMS = null;
          }
        }).catch((err) => {
          console.log(p.url, 'Error', err)
          t.messageError = err;
          t.userCredenctial.phone = null;
          t.userCredenctial.codeSMS = null;
        });
    },
  },
  computed: {
    ...mapGetters({
      stateApi: 'getStateApi',
      loadingApi: 'getLoadingApi',
      loadingApiProgress: 'getLoadingApiProgress',
      apiResponse: 'getApiResponse',
      apiError: 'getApiError',
    }),
    isLoading: {
      get: function () {
        return this.loadingApi;
      },
      set: function (v) {
        return v;
      },
    }
  },
  watch: {
    stateApi: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log('stateApi', newVal);
      }
    },
    loadingApi: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log('loadingApi', newVal);
        this.isLoading = newVal;
      }
    },
    loadingApiProgress: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log('loadingApiProgress', newVal);
      }
    },
    apiResponse: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log('apiResponse', newVal);
      }
    },
    apiError: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log('apiError', newVal);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #fbfbfb;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 1px #dbdbda;
  position: relative;
  border-radius: 2px;
  margin-top: 0px;
}

.card-header {
  padding: 15px 13px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  background: rgba(248, 248, 248, .9);
}

.card-header h4 {
  margin-bottom: 0px;
  color: #555;
}

.card-footer {
  display: block;
  padding: 7px 14px 15px;
  border-top: 1px solid #ddd;
  background: rgba(248, 248, 248, .9);
}

button.btn.btn-success.btn-login {
  padding: 10px 35px;
  border-color: #031099;
  background-color: #031099;
  box-shadow: none;
  width: 100%;
  margin: auto;
  display: block;
  font-size: 20px;
}

.btn-sing {
  padding: 2px 10px;
  color: #1f1f1f;
  background-color: #01972000;
  border-color: #1f1f1f;
  border-radius: 2px;
  float: right;
}

.creat-account {
  border-color: #aaa;
}

form label {
  display: block;
  margin-bottom: 6px;
  line-height: 19px;
  font-weight: 400;
  font-size: 13px;
  color: #333;
  text-align: left;
  white-space: normal;
}

.form-control {
  border: 0px solid #bdbdbd;
  background-color: #fbfbfb !important;
  height: 50px;
  border-bottom: 1px solid #bdbdbd;
  border-radius: 0px;
}

.form-control:focus {
  background-color: #fbfbfb !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
  background-color: #fbfbfb !important;
}

.showpassord {
  height: 50px;
  border-left: 0px solid;
}

.note {
  margin-top: 6px;
  padding: 0 15px;
  font-size: 11px;
  line-height: 15px;
  color: #999;
}

.note a {
  margin-top: 20px;
  font-size: 14px;
  display: block;
  text-align: right;
}

@media (max-width: 1190px) {

  .btn.btn-success.btn-login,
  .btn.btn-sing.creat-account {
    display: block;
    margin: 10px auto;
    width: 80%;
    height: 40px;
    font-size: 20px;
    float: initial;
  }

  .Ajust {
    width: 74% !important;
    margin-top: -13px !important;
  }
}

@media (min-width: 1190px) {
  .Ajust {
    width: 90px !important;
    margin-right: 15px !important;
    margin-top: -42px !important;
    position: absolute !important;
    right: 1px !important;
  }
}

@media (max-width: 767px) {
  .card {
    max-width: 330px;
    margin: auto;
  }
}

@media (max-width: 350px) {
  .card {
    max-width: 300px;
    margin: auto;
  }
}</style>
