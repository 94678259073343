import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/';
import validRouters from './validRouters'
import PathNotFound from '@/core/views/Error404View'
import AuthLayout from '@/core/Layouts/AuthLayout.vue'
import LoginLayout from '@/core/Layouts/LoginLayout.vue'
import LoginSupport from '@/core/Layouts/LoginSupport.vue'
import DashLayout from '@/core/Layouts/DashLayout.vue'
import AccView from '@/core/views/AccView.vue'
import OrgView from '@/core/views/OrgView.vue'
import SMView from '@/core/views/SMView.vue'
import bSiteView from '@/core/views/bSiteView.vue'

const rutas_apps = [
  {
    path: '/',
		meta: { requiresAuth: false },
    name: 'Auth',
    component: AuthLayout,
    folder: AuthLayout
  },
  {
    path: '/login',
		meta: { requiresAuth: false },
    name: 'Login',
    component: LoginLayout,
    folder: LoginLayout
  },
  {
    path: '/login-support/:p/:c',
		meta: { requiresAuth: false },
    name: 'LoginSupport',
    component: LoginSupport,
    folder: LoginSupport
  },
  {
    path: '/',
		meta: { requiresAuth: true },
    folder: DashLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dash',
        component: AccView,
        folder: AccView
      },
      {
        path: '/organizations',
        name: 'OrgView',
        component: OrgView,
        folder: OrgView
      },
      {
        path: '/smart-office',
        name: 'SMView',
        component: SMView,
        folder: SMView
      },
      {
        path: '/bSite',
        name: 'bSiteView',
        component: bSiteView,
        folder: bSiteView
      },
    ]
  },
  // {
  //   path: '/about',
	// 	meta: { requiresAuth: true },
  //   name: 'about',
  //   component: AboutView,
  //   folder: AboutView
  // }
  // {
    // path: '/about',
    // name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];
const ruta_error = [
  {path: '/:pathMatch(.*)', meta: { requiresAuth: false }, component: PathNotFound, folder: PathNotFound},
];
// unir las rutas
let rutas_new = [];
rutas_new = rutas_new.concat(rutas_apps);
rutas_new = rutas_new.concat(ruta_error);
// convertir a objeto router
let rutas = rutas_new.map(r => validRouters.route(r.path, r.folder, r.name, r.meta, r.children));
// console.log('rutas', rutas);



const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: rutas
})
router.beforeEach((to, from, next) => {
  // debugger;
  const viewAuth = to.matched.some(record => record.meta.requiresAuth);
  // console.log('viewAuth', viewAuth)
  if (viewAuth) {
    // console.log('pagina privada', store.getters['getLoggedIn'])
    if (!store.getters['getLoggedIn']) {
      console.log('redirigir a login');
      next({ name: 'Login' });
    } else {
      console.log('acceso permitido', store.getters['getLoggedIn']);
      next()
    }
  }else{
    // paginas publicas
    // console.log('pagina publica');
    next();
  }
})

export default router
