<template>
    <div>
        <div class="modal fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document" style="max-width: 800px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Login Information:</h5>
                        <button type="button" class="close" @click="$emit('emitClose', 0)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-if="isLoading">
                            <div class="col-12">
                                <Loader></Loader>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-12">
                                <div class="card ">
                                    <div class="card-body ">
                                        <table class="table table-striped table-inverse" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col" style="width:220px;text-align: left;">Organization name</th>
                                                    <th scope="col" style="width:220px;text-align: left;">Contact Name</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Primary</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="result.length === 0">
                                                <tr>
                                                    <th colspan="10">
                                                        <div style="text-align: center">
                                                            no record
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr v-for="(i, index) in result" :key="index">
                                                    <td>{{ i.id }}</td>
                                                    <td style="width:220px;text-align: left;">{{ i.bill_company_name }}</td>
                                                    <td style="width:220px;text-align: left;">{{ i.bill_firstname }} {{ i.bill_lastname }}</td>
                                                    <td>
                                                        <span v-if="i.active == 1" class="badge badge-pill badge-success">
                                                            Active </span>
                                                        <span v-else class="badge badge-pill badge-danger"> Inactive </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="i.principal == 1" class="badge badge-pill badge-success">
                                                            Primary
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="messageError != null">
                            <div class="col-md-12">
                                <div class="alert alert-danger">
                                    {{ messageError }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            result: [],
            messageError: null,
            dataModal: null,
        };
    },
    created() {
        this.dataModal = this.$store.getters['getDataModal'];
        console.log('dataModal', this.dataModal);
        if (this.dataModal && this.dataModal.userID != null && this.dataModal.userID > 0) {
            this.getInfo(this.dataModal.userID);
        }
    },
    methods: {
        getInfo(user_id) {
            let t = this;
            t.isLoading = true
            t.messageError = null;
            let p = {
                url: 'r2/account/list/' + user_id,
                data: t.f
            };
            t.$store.dispatch('apiRest', p)
                .then((response) => {
                    console.log('accountInfo', response)
                    t.isLoading = false;
                    t.result = response.orgs;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false
                    t.messageError = error.message;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    position: relative;
    text-align: left;
    margin-bottom: 0.5rem;
}
@media (min-width: 701){
    .modal-dialog {
        max-width: 800px;
        margin: 1.75rem auto;
    }
}
</style>
