<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-1" >
        <h1 class="card-title">bSite</h1>
      </div>
      <div class="col-sm-2" >
        <h4 class="card-title">App Status</h4>
        <p>
          <label class="filter" @click="filterStatus('statusApp', 1)" for="actives">
            <i v-bind:class="[(statusAppFilter.includes(1)) ? 'fas fa-check-circle' : 'far fa-circle']" style="margin-right:5px;"></i>
            <span class="badge badge-success" style="margin-right:5px;">{{ contadorStatusAppActive }}</span> Active
            <i v-if="(statusAppFilter.includes(1))" class="fas fa-times-square" style="margin-left:5px;"></i>
          </label>
          <label class="filter" @click="filterStatus('statusApp', 2)" for="suspended">
            <i v-bind:class="[(statusAppFilter.includes(2)) ? 'fas fa-check-circle' : 'far fa-circle']" style="margin-right:5px;"></i>
            <span class="badge badge-danger" style="margin-right:5px;">{{ contadorStatusAppSuspended }}</span> Suspended
            <i v-if="(statusAppFilter.includes(2))" class="fas fa-times-square" style="margin-left:5px;"></i>
          </label>
          <label class="filter" for="suspended">
            <!-- @click="filterStatus('statusApp', 3)" <i v-bind:class="[(statusAppFilter.includes(3)) ? 'fas fa-check-circle' : 'far fa-circle']" style="margin-right:5px;"></i> -->
            <span class="badge badge-danger cancelled" style="margin-left:20px;margin-right:5px;">{{ contadorStatusAppCancelled }}</span> Cancelled
            <!-- <i v-if="(statusAppFilter.includes(3))" class="fas fa-times-square" style="margin-left:5px;"></i> -->
          </label>
        </p>
      </div>
      <div class="col-sm-2">
        <h4 class="card-title">App Plans</h4>
        <p>
          <span v-for="(plan, planIdx) in listPlanes" :key="planIdx">
            <label class="filter" @click="filterStatus('statusPlan', plan.id)" for="standar">
              <i v-bind:class="['far', (planFilter.includes(plan.id)) ? ' fa-circle-check' : 'fa-circle']" style="margin-right:5px;"></i>
              <span :class="['badge', 
                (plan.id==5) ? 'badge-info' : 
                  (plan.id==6) ? 'badge-warning badgeOro' : 
                    (plan.id==7) ? 'badge-primary' : 
                      (plan.id==9) ? 'badge-naranja' : 
                        (plan.id==11) ? 'badge-amarillo-google' :
                        (plan.id==10) ? 'badge-success' : 'badge-secondary'
                ]" style="margin-right:5px;"
              >{{contadorPlan[plan.id]}}</span> {{ plan.name }}
              <i v-if="(planFilter.includes(plan.id))" class="fas fa-times-square" style="margin-left:5px;"></i>
            </label>
          </span>
        </p>
      </div>
      <div class="col-sm-2">
        <h4 class="card-title">Average</h4>
        <p>
          <label class="filter" > <!-- @click="filterStatus('statusAmount', 1)" -->
            <!-- <i v-bind:class="['far', (amountFilter.includes(1)) ? ' fa-circle-check' : 'fa-circle']"></i> -->
            Monthly: <span class="badge badge-secondary" style="">${{formatMoney(totalMonthly)}}</span>
            <!-- <i v-if="(amountFilter.includes(1))" class="fas fa-times-square"></i> -->
          </label>
          <label class="filter" > <!-- @click="filterStatus('statusAmount', 2)" -->
            Annual: <span class="badge badge-info">${{formatMoney(totalAnnual)}}</span>
          </label>
          <!-- <label class="filter"  > -->
            <!-- <@click="filterStatus('statusPay', 0)" -->
            <!-- <i v-bind:class="['far', (payFilter.includes(0)) ? ' fa-check-square' : 'fa-square']"></i> -->
            <!-- Unpaid: <span class="badge badge-danger">${{amountUnpaid}}</span> -->
          <!-- </label> -->
        </p>
      </div>
      <div class="col-md-4">
        <h4 class="card-title">Search:</h4>
        <div class="row">
				<div class="input-group mb-2">
					<div class="input-group-prepend">
						<div class="input-group-text" @click="backspaceSearch" style="cursor:pointer;">
							<span class="input-group-text" style="border: 0px !important; padding: 4px !important;">
                <i class="fa fa-backspace fa-rotate-180"></i>
							</span>
						</div>
					</div>
					<input type="text" class="form-control" id="searchInput" v-model="searchInput" placeholder="" style="border: 1px solid #c9c9ca;" />	
				</div>
			</div>
      </div>
    </div>
    <div class="row">
      <div class="card-body tdynamic">
        <div class="content-table-tdynamic">
          <table class="table-tdynamic" id="tableID">
            <thead>
              <tr>
                <th class="accIdResellerNote">Acc Id</th>
                <th class="colOrgInfo">Organization</th>
                <th class="lastActivity">Last Activity</th>
                <th class="created">Creation</th>
                <th class="plan">Plan</th>
                <th class="amount">Amount</th>
                <th class="status-app">App Status</th>
              </tr>
            </thead>
            <tbody v-if="isLoading === true">
              <tr>
                <th :colspan="colspan" style="text-align: center">
                  <loader></loader>
                </th>
              </tr>
            </tbody>
            <tbody v-else-if="mapOrgListbSite.length === 0">
              <tr>
                <th :colspan="colspan" style="text-align: center">
                  No record
                </th>
              </tr>
            </tbody>
            <tbody v-else>
              <template v-for="(i, index) in mapOrgListbSite" :key="index">
                <tr v-bind:class="['row-tr-'+index, (i.statusAppCRM==3) ? 'hide' : '']">
							<!-- <td class="count text-center"></td> @click="modalBilling(i, 'Note')"-->
							<td class="accIdResellerNote">
								<!-- <button type="button"
									title="Edit Notes"
                  @click="notes(i)"
									:class="[(i.notes.length > 0) ? 'btn btn-warning' : 'btn btn-secundary']"
									style="float: left; padding: 0px 3px; font-size: 10px; height: 16px;margin-right: 5px;"
								>
									<i class="far fa-sticky-note"></i>
									<span class="textIcon" style="display: none;"> {{i.notes}}</span>
								</button> -->
								<div @click="openEditBillInfo(i)" style="cursor: pointer;">{{i.userID}}</div>
							</td>
							<td class="colOrgInfo">
								#{{ i.id }} <b>{{i.bill_company_name}}</b><br>
								{{i.bill_firstname}} {{ i.bill_lastname }} 
								<br v-if="i.bill_firstname || i.bill_lastname">
								{{i.bill_email}}
								<br v-if="i.status == 0">
								<span class="badge badge-danger" v-if="i.status==0">Suspended</span> 
							</td>
							<td class="lastActivity"  style="cursor:pointer;">
								<!-- @click="ActivityLogDetails(i)"-->
                See Details
							</td>
							<td class="created">
								<span v-if="Object.keys(i.info).length>0" >
									{{ i.created_date }}<br>{{ i.created_time }}
								</span>
							</td>
							<td class="plan" style="cursor:pointer;">
                <div class="action_btn_status">
                  <span v-if="i.info.plan_id==8" class="badge badge-secondary" @click="activePlan(index)">Free</span>
                  <span v-else-if="i.info.plan_id==7" class="badge badge-primary" @click="activePlan(index)">Sponsored</span>
                  <template v-for="(plan, planIdx) in listPlanes" :key="planIdx" v-else>
                    <span v-if="i.info.plan_id == plan.id" :class="['badge',
                      (plan.id==5) ? 'badge-info' : 
                        (plan.id==6) ? 'badge-warning badgeOro' : 
                          (plan.id==7) ? 'badge-primary' : 
                            (plan.id==9) ? 'badge-naranja' : 
                              (plan.id==10) ? 'badge-success' : 'badge-secondary'
                        ]"
                        @click="activePlan(index)"
                    >
                      {{ plan.name }}
                    </span>
                  </template>
                  <span class="status_list" v-show="planActive == index">
                    <ul class="reset" style="padding-left: 0; ">
                      <template v-for="(p, indexs) in listPlanes" :key="indexs">
                        <li v-show="p.id!=i.info.plan_id" style="margin-bottom: 5px;">
                          <a href="#"
                              @click.prevent="changePlan(i, p.id,)"
                              v-bind:class="[
                              'badge',
                                (p.id==5) ? 'badge-info' : 
                                  (p.id==6) ? 'badge-warning badgeOro' : 
                                    (p.id==7) ? 'badge-primary' : 
                                      (p.id==9) ? 'badge-naranja' : 
                                        (p.id==10) ? 'badge-success' : 'badge-secondary'
                              ]"
                              style="display: block;text-align: left;font-size: 12px;"
                            >
                              {{ p.name }}
                            </a>
                        </li>
                      </template>
                    </ul>
                  </span>
                </div>
							</td>
							<td class="amount">
								${{i.info.plan_amount}}
							</td>
							<td class="status-app">
                <div class="action_btn_status">
                  <span @click="activeStatus(index)" v-bind:class="[
                    'statusSelected badge',
                    (i.statusAppCRM==1) ? 'badge-success' : '',
                    (i.statusAppCRM==2) ? 'badge-danger' : '',
                    (i.statusAppCRM==3) ? 'badge-danger cancelled' : '',
                  ]" style="cursor: pointer">
                    <span>{{i.statusAppName}}</span>
                    <!-- <i class="fas fa-angle-down"></i> -->
                  </span>
                  <span class="status_list" v-if="statusActive == index">
                    <ul class="reset" style="padding-left: 0; ">
                      <template v-for="(p, indexs) in listStatusApp" :key="indexs">
                        <li v-show="p.statusID!=i.statusAppCRM" style="margin-bottom: 5px;">
                          <a href="#"
                              @click.prevent="changeStatusApp(i, p.statusID, indexs)"
                              v-bind:class="[
                              'badge',
                              (p.statusID==1) ? 'badge-success' : '',
                              (p.statusID==2) ? 'badge-danger' : '',
                              (p.statusID==3) ? 'badge badge-danger cancelled' : '',
                              ]"
                              style="display: block;text-align: left;font-size: 12px;"
                            >
                              {{p.nameStatus}}
                            </a>
                        </li>
                      </template>
                    </ul>
                  </span>
                </div>
							</td>
						</tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <component :is="nameCompModal" v-if="isShowModal" @emitClose="getEmitcloseModal($event)"></component>
  </div>
</template>
<script>
  // import { mapGetters } from 'vuex';
  import EditBillInfo from '@/core/components/AccM/EditBillInfo'
  import Loader from '@/core/components/shared/LoaderProgress'
  export default {
    name: 'OrgbyReseller',
    components: {
      Loader,
      EditBillInfo,
    },
    data() {
      return {
        colspan: 7,
        isLoading: false,
        searchInput:'',
        lists: [],
        lists_OLD: [],
        listPay: [],
        urlPremium: null,
        statuslist: [],
        statusAppFilter: [1],
        resellerFilter: [],
        planeslist: [],
        planFilter: [5,9,10,6,8],
        payFilter: [],
        amountFilter: [],
        f: {
          resellerId: localStorage.getItem('resellerId'),
        },
        isShowModal: false,
        nameCompModal: null,
        contadorStandar: 0,
        contadorPremium: 0,
        contadorSponsored: 0,
        contadorFreePlan: 0,
        amountMonthly: 0,
        totalMonthly: 0,
        amountAnnual: 0,
        totalAnnual: 0,
        amountUnpaid: 0,
        contadorStatusAppActive: 0,
        contadorStatusAppSuspended: 0,
        contadorStatusAppCancelled: 0,
        contadorUnpaid: 0,
        contadorPaid: 0,
        contadorCancelled: 0,
        contadorDeclined: 0,
        statusActive: null,
        planActive: null,
        listPlanes: [],
        listPlanesOrderById: [5,9,10,6,8,7,11],
        contadorPlan: [],
      }
    },
    created() {
      console.log('view  bSite')
      this.init();
    },
    methods: {
      init(){
        let t = this;
        t.isLoading = true;
        let p = {
          url: 'r2/listStatusBilling',
          data: t.f
        };
        t.$store.dispatch('apiRest', p)
        .then((response) => {
          t.urlPremium = response.urlPremium;
          t.planeslist = response.planeslist;
          t.statuslist = response.systemStatusList;
          t.listStatusApp = response.statusApp;
          t.getOrgWithbSiteApp();
          })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
      },
      formatMoney(value){
        return Number(value).toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },
      reporteAmount(){
        let t = this;
        t.amountAnnual = (isNaN(t.amountAnnual)) ? 0 : t.amountAnnual;
        t.amountMonthly =  (isNaN(t.amountMonthly)) ? 0 : t.amountMonthly;
        t.totalMonthly =t.amountMonthly + (t.amountAnnual/12);
        t.totalAnnual = (t.amountMonthly*12) + t.amountAnnual;
      },
      getOrgWithbSiteApp() {
        let t = this;
        t.isLoading = true
        t.contadorOrgsActives = 0;
        t.contadorOrgsSuspended = 0;
        t.contadorStandar = 0;
        t.contadorPremium = 0;
        t.contadorPaid = 0;
        t.contadorUnpaid = 0;
        t.contadorCancelled = 0;
        let p = {
          url: 'r2/getbSite',
          data: t.f
        };
        t.$store.dispatch('apiRest', p)
          .then((response) => {
            t.lists = []
            t.lists = response.list;
            console.log('getbSite records', t.lists.length);
            let listPlanes = response.planes;
            if(listPlanes !=null && listPlanes.length>0){
              listPlanes.filter(plan =>{
                let valid = false;
                for(let j=0; j<t.listPlanes.length; j++){
                  if(t.listPlanes[j].id==plan.id){
                    valid=true;
                  }
                }
                if(!valid){
                  t.listPlanes.push(plan);
                }
                if(plan.id!=7){
                  t.planFilter.push(plan.id);
                }
              });
            }
            let o = t.lists
            let u = t.modelarLista(o);
            let p = t.sortedKeyValue(u, 'id')
            t.lists = []
            t.lists = p
            t.lists_OLD=response.list;
            t.reporteAmount();
            t.isLoading = false
          })
          .catch((error) => {
            console.log(error);
            t.isLoading = false
          });
      },
      sortedKeyValue(obj, key) {
        return obj.sort((a, b) => {
          let x = a[key];
          let y = b[key];
          return ((y < x) ? -1 : ((y > x) ? 1 : 0)); // ordenar de mayor a menor
          // return ((x < y) ? -1 : ((x > y) ? 1 : 0)); // ordenar de menor a mayor
        });
      },
      modelarLista(o){
        let t = this;
        let u=[];
        t.contadorStandar=0;
        t.contadorPremium=0;
        t.contadorSponsored=0;
        t.contadorFreePlan=0;
        t.amountMonthly=0;
        t.amountAnnual=0;
        t.amountUnpaid=0;
        t.contadorStatusAppActive=0;
        t.contadorStatusAppSuspended=0;
        t.contadorStatusAppCancelled=0;
        t.contadorUnpaid=0;
        t.contadorPaid=0;
        t.contadorCancelled=0;
        t.contadorDeclined=0;
        t.contadorPlan = [];
        for (let planIdx in t.listPlanes) {
          if(t.listPlanes[planIdx]){
            t.contadorPlan[t.listPlanes[planIdx].id]=0;
          }
        }
        for (let i in o) {
          // let user_id = o[i].user_id
          // let id = o[i].id
          o[i].moreOpen='hide';
          if(o[i].statusAppCRM == 1){t.contadorStatusAppActive += 1;}
          if(o[i].statusAppCRM == 2){t.contadorStatusAppSuspended += 1;}
          if(o[i].statusAppCRM == 3){t.contadorStatusAppCancelled += 1;}
          if(t.statusAppFilter.length == 0 || t.statusAppFilter.includes(eval(o[i].statusAppCRM)))
          {
            // console.log('active', o[i].id)
            for (let planIdx in t.listPlanes) {
							if(t.listPlanes[planIdx]){
								if(o[i].info && o[i].info.plan_id == t.listPlanes[planIdx].id && o[i].statusAppCRM!=3){
									t.contadorPlan[t.listPlanes[planIdx].id]++;
								}
							}
						}
            if(o[i].statusAppCRM != 3){
							if(o[i].renewal_bSite_interval ==1){t.amountMonthly+= eval(o[i].info.plan_amount);}
							if(o[i].renewal_bSite_interval ==2){t.amountAnnual+= eval(o[i].info.plan_amount);}
						}
            if(o[i].statusAppCRM != 3 && o[i].statusPay == '0'){
              t.amountUnpaid+= eval(o[i].info.plan_amount);
            }
            if(o[i].statusAppCRM!=3 &&  o[i].statusPay == 0){t.contadorUnpaid += 1; }
            if(o[i].statusAppCRM!=3 &&  o[i].statusPay == 1){t.contadorPaid += 1; }
            if(o[i].statusAppCRM!=3 &&  o[i].statusPay == 3){t.contadorDeclined += 1; }
            if(o[i].statusPay == 2){t.contadorCancelled += 1; }
            u.push(o[i]);
          }else{
            console.log('exclude', o[i].id, o[i].statusAppCRM)
          }
        }
        return u;
      },
      closeModal() {
        this.isShowModal = false;
        this.nameCompModal = null;
        this.$store.commit('setDataModal', null);
      },
      showModal() {
        this.isShowModal = true;
      },
      getEmitcloseModal(value) {
        console.log('getEmitcloseModal', value);
        this.closeModal();
        if (value == 1) {
          this.getOrgWithbSiteApp();
        }
      },
      openEditBillInfo(i) {
        this.$store.commit('setDataModal', i);
        this.nameCompModal = "EditBillInfo";
        this.showModal()
      },
      activeStatus(index) {
        if (this.statusActive == index) {
          this.statusActive = null;
        } else {
          this.statusActive = index;
        }
        console.log('activeStatus', this.statusActive)
      },
      activePlan(index) {
        if (this.planActive == index) {
          this.planActive = null;
        } else {
          this.planActive = index;
        }
        console.log('activePlan', this.planActive)
      },
      notes(i){console.log('notes', i)},
      changePlan(i, planID){
        console.log('plan', i)
        let t = this;
        t.isLoading = true;
        t.planActive = null;
        let p = {
          url: 'r2/setPlanbSite',
          data: {
            org_id: i.id,
            user_id: i.user_id,
            planes: planID,
            plan_id: planID,
            reseller_id: localStorage.getItem('resellerId'),
          }
        };
        t.$store.dispatch('apiRest', p)
        .then((response) => {
          console.log('formPlan', response)
          t.getOrgWithbSiteApp();
        })
        .catch((error) => {
          t.isLoading = false;
          console.log(error);
        });
      },
      RenewalDates(renewal, i){console.log('RenewalDates', renewal, i)},
      changeStatusPay(user_id, id, statusID){console.log('changeStatusPay', user_id, id, statusID)},
      changeStatusApp(i, statusID, idx){
        console.log('changeStatusApp', i.user_id, i.id, statusID, idx)
        let t = this;
        t.statusActive = null;
        let send = true;
        if(statusID==3){
          if(confirm('Are you sure you want to cancel this App?')){
            send = true;
          }else{send = false;}
        }
        if(send)
        {
          let valueCampo = t.listStatusApp[idx].nameStatus
          let namecampo= 'statusAppName';
          let p = {
          url: 'r2/getStatusAppbSite',
          data: {
            user_id: i.user_id,
            org_id: i.id,
            meta_key: 'statusAppCRM',
            meta_value: statusID,
            nameCampo: valueCampo,
            campo: namecampo,
            reseller_id: localStorage.getItem('resellerId'),
          }
        };
        t.$store.dispatch('apiRest', p)
          .then((response) => {
            console.log('changeStatusApp', response);
            t.getOrgWithbSiteApp();
            // if(statusID==3 && response.status){}
            t.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
        }
      },
      filterStatus(filter, status){
        let t = this;
        if(filter == 'statusApp'){
          if(!t.statusAppFilter.includes(status)){
            t.statusAppFilter.push(status); // add
            console.log('add statusApp', t.statusAppFilter);
          }
          else{
            t.statusAppFilter.splice(t.statusAppFilter.indexOf(status), 1) // remover
            console.log('rm statusApp', t.statusAppFilter);
          }
        }
        else if(filter == 'statusPlan'){
          if(!t.planFilter.includes(status)){
            t.planFilter.push(status); // add
            console.log('add statusPlan', t.planFilter);
          }
          else{
            t.planFilter.splice(t.planFilter.indexOf(status), 1); // remover
            console.log('rm statusPlan', t.planFilter);
          }
        }
        let listBillOrg = [];
        console.log('lists_OLD', t.lists_OLD.length);
        for (let i in t.lists_OLD) { listBillOrg.push(t.lists_OLD[i]); } // creando copia a editar
        t.lists = t.modelarLista(listBillOrg);
        t.reporteAmount();
        console.log('lists', t.lists.length);
      },
      buscartext(lists){
        var t = this;
        return lists.filter(function (item) {
            let fullname = item.bill_firstname+' '+item.bill_lastname
            let fullnameA = item.bill_firstname+' '+item.bill_company_name
            let fullnameB = item.bill_lastname+' '+item.bill_firstname
            let fullnameC = item.bill_lastname+' '+item.bill_company_name
            let fullnameD = item.bill_company_name+' '+item.bill_firstname
            let fullnameE = item.bill_company_name+' '+item.bill_lastname
            let fullnameF = item.bill_company_name+' '+item.bill_lastname
            return (
              (item.bill_lastname &&
                item.bill_lastname
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (item.bill_firstname &&
                item.bill_firstname
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (item.bill_email &&
                item.bill_email
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullname &&
                fullname
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameA &&
                fullnameA
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameB &&
                fullnameB
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameC &&
                fullnameC
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameD &&
                fullnameD
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameE &&
                fullnameE
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0) ||
              (fullnameF &&
                fullnameF
                  .toLowerCase()
                  .indexOf(t.searchInput.toLowerCase().trim()) >= 0)
            );
          });
      },
    },
    computed: {
      mapOrgListbSite: function () {
        var t = this;
        var listCheck = [];
        let fill1 = t.statusAppFilter.length;
        let fill2 = t.planFilter.length;
        let  searchT=t.searchInput.length;
        if(searchT>0 || fill1>0 || fill2>0){
          // console.log('mapOrgListbSite', searchT>0, fill1>0, fill2>0)
          listCheck= t.lists.filter(function (item) {
            let checkFilter= false;
            // console.log('item', item.id, item.statusAppCRM, t.statusAppFilter.includes(eval(item.statusAppCRM)))
            let c1 = t.statusAppFilter.includes(eval(item.statusAppCRM)); // status app
            let c2 = t.planFilter.includes(eval(item.info.plan_id)); // planes
            // validacion en cascada segun jerarquia
            if(c1 && c2 && fill1>0 && fill2>0){checkFilter= true; console.log('filter1')}
            else if(c1 && !c2 && fill1>0 && fill2==0){checkFilter= true; console.log('filter2')}
            else if(!c1 && c2 && fill1==0 && fill2>0){checkFilter= true; console.log('filter3')}
            //124
            if(checkFilter) {
              console.log('filter', item);
              return item;
            }
          });
          return t.buscartext(listCheck);
        }
        else{
          return t.lists;
        }
      },
      // ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
    },
  }
</script>
<style lang="scss" scoped>
  @import '../assets/css/tdynamic.css';
  .page-wrapper > .container-fluid {
    padding-top: 0px;
  }
  .tdynamic {
    height: calc(100vh - 240px);
  }
  .tdynamic .content-table-tdynamic {
    top: 0px;
  }
  .table-tdynamic thead tr th {
    font-weight: bold;
  }
  .table-tdynamic thead tr th,
  .table-tdynamic tbody tr td {
    padding: 1px 8px;
  }
  .table-tdynamic thead tr th.colMore,
  .table-tdynamic tbody tr td.colMore {
    width: 30px;
  }
  .table-tdynamic tbody tr td.colMore:hover {
    cursor: pointer;
  }
  .table-tdynamic thead tr th.accIdResellerNote,
  .table-tdynamic tbody tr td.accIdResellerNote {
    width: 80px;
  }
  .table-tdynamic thead tr th.colOrgInfo,
  .table-tdynamic tbody tr td.colOrgInfo {
    width: 220px;
    text-align: left;
  }
  .table-tdynamic thead tr th.lastActivity,
  .table-tdynamic tbody tr td.lastActivity {
    width: 100px;
    text-align: center;
  }
  .table-tdynamic thead tr th.colActions,
  .table-tdynamic tbody tr td.colActions {
    width: 80px;
  }
  .table-tdynamic thead tr th.status-app,
  .table-tdynamic tbody tr td.status-app {
    width: 80px;
    text-align: center;
  }
  .table-tdynamic thead tr th.created,
  .table-tdynamic tbody tr td.created {
    width: 130px;
  }
  .table-tdynamic thead tr th.plan,
  .table-tdynamic tbody tr td.plan {
    width: 80px;
    text-align: center;
  }
  .table-tdynamic thead tr th.amount{text-align: center;}
  .table-tdynamic tbody tr td.amount{text-align: right;}
  .table-tdynamic thead tr th.amount,
  .table-tdynamic tbody tr td.amount {
    width: 130px;
  }
  .status_list {
    padding: 0px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    /* width: 120px; */
    font-size: 14px;
    z-index: 9;
  }
  .status_list>ul {
    margin-bottom: 0px;
    border: 1px solid #ccc;
  }
  .status_list>ul>li {
    display: block;
    position: relative;
    margin: 1px 0;
    padding: 5px;
  }
  .status_list>ul>li:hover {
    background-color: #efefef;
    cursor: pointer;
  }
  .action_btn_status {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
  }
  span.statusSelected {
    font-size: 12px;
  }
  span.statusSelected i {
    color: #000;
  }
  .badgeOro {
    background-color: #f1c94c;
    color: #606060;
  }
  .tableRow {
    width: 100%;
    border: 0px solid;
    border-collapse: initial;
    border-bottom: 0px solid #eee;
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
    display: table;
    text-indent: initial;
    border-spacing: 0px;
    border-color: grey;
  }
  .cdropdownx>a:before,
  .cdropdownx>button:before {
    position: absolute;
    right: 7px;
    top: 15px;
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  .dropx {
    background-color: transparent !important;
    border: 0px solid #ccc !important;
    color: white !important;
    padding: 0px 15px 0px 0px !important;
  }
  .usersbyorg {
    width: 100%;
    border: 1px solid #ccc;
    margin: 10px 0px;
  }
  .usersbyorg thead tr th {
    border-top: 1px solid #ccc;
  }
  .content-tr-extension {
    background-color: #fff !important;
    margin-left: 0px;
    margin-right: 0px;
  }
  .filter:hover {
    cursor: pointer;
  }
  .filterOff {}
  .filterOn::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .filterOn.bg-danger.unpaid,
  .filterOn.bg-success.paid,
  .filterOn.bg-danger.cancelled {
    color: #fff;
  }
  .filterOn.bg-warning.declined {
    color: #000;
  }
  .filterOn.bg-secondary.unpaid {
    color: #fff;
    background-color: #6c757d;
  }
  .filterOn.bg-warning.primium {
    background-color: #f1c94c;
    color: #606060;
  }
  .filterx {
    cursor: pointer;
    border-left: 1px solid rgb(211, 217, 217);
    border-radius: 0px !important;
    padding: 1px !important;
    font-size: inherit !important;
  }
  .filtery {
    border: 1px solid rgb(211, 217, 217);
    padding: 0.1px;
    background-color: #fff;
  }
  .filterz {
    border-radius: 0px !important;
    padding: 0.4px 3px !important;
    font-size: inherit !important;
  }
  label.filter {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .cancelled{
    background-color: #c52506 !important;
  }
  .badge-naranja {
      color: #ffffff;
      background-color: #ff8720;
  }
  .badge-amarillo-google{
      color: #ffffff;
      background-color: #fea510;
  }
</style>
