<template>
    <div>
        <div class="modal fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Billing Information:</h5>
                        <button type="button" class="close" @click="$emit('emitClose', 0)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-if="isLoading">
                            <div class="col-12"><Loader></Loader></div>
                        </div>
                        <div class="row" v-else>
                            <div class="form-group col-md-12">
                                <label>Company Name:</label>
                                <input type="text" v-model="f.bill_company_name" class="form-control" />
                            </div>
                            <div class="form-group col-md-6">
                                <label>First Name:</label>
                                <input type="text" v-model="f.bill_firstname" class="form-control" />
                            </div>
                            <div class="form-group col-md-6">
                                <label>Last name:</label>
                                <input type="text" v-model="f.bill_lastname" class="form-control" />
                            </div>
                            <div class="form-group col-md-12">
                                <label>Address:</label>
                                <input type="text" v-model="f.bill_address" class="form-control" />
                            </div>
                            <div class="form-group col-md-4">
                                <label>City:</label>
                                <input type="text" v-model="f.bill_city" class="form-control" />
                            </div>
                            <div class="form-group col-md-4">
                                <label>State:</label>
                                <input type="text" v-model="f.bill_state" class="form-control" />
                            </div>
                            <div class="form-group col-md-4">
                                <label>Zip Code:</label>
                                <input type="text" v-model="f.bill_zip_code" class="form-control" />
                            </div>
                            <div class="form-group col-md-6">
                                <label>Telephone:</label>
                                <input type="text" v-model="f.bill_telephone" class="form-control" />
                            </div>
                            <div class="form-group col-md-6">
                                <label>Website:</label>
                                <input type="text" v-model="f.bill_website" class="form-control" />
                            </div>
                        </div>
                        <div class="row" v-if="messageError!=null">
                            <div class="col-md-12">
                                <div class="alert alert-danger">
                                    {{ messageError }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="saveform()">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            f: {
                org_id: 0,
                user_id: 0,
                bill_company_name: null,
                bill_firstname: null,
                bill_lastname: null,
                bill_address: null,
                bill_city: null,
                bill_state: null,
                bill_zip_code: null,
                bill_telephone: null,
                bill_website: null,
            },
            messageError: null,
            dataModal: null,
        };
    },
    created(){
        this.dataModal = this.$store.getters['getDataModal'];
        console.log('dataModal', this.dataModal);
        if(this.dataModal && this.dataModal.userID!=null && this.dataModal.userID>0){
            this.getInfo(this.dataModal.userID);
        }
    },
    methods: {
        getInfo(user_id) {
            let t = this;
            t.isLoading = true
            t.messageError = null;
            let p = {
                url: 'r2/accountInfo/'+user_id,
                data: t.f
            };
            t.$store.dispatch('apiRest', p)
            .then((response) => {
                console.log('accountInfo', response)
                t.isLoading = false;
                t.f = response.bill;
                if(this.dataModal.id){
                    t.f.org_id = this.dataModal.id;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false
                t.messageError = error.message;
            });
        },
        saveform() {
            let t = this;
            t.isLoading = true
            t.messageError = null;
            let p = {
                url: 'r2/editBillInfo',
                data: t.f
            };
            t.$store.dispatch('apiRest', p)
            .then((response) => {
                console.log(response)
                t.isLoading = false;
                if(response.status){
                    t.$emit('emitClose', 1);
                }else{
                    t.messageError = response.msj;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false
                t.messageError = error.message;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    position: relative;
    text-align: left;
    margin-bottom: 0.5rem;
}
</style>