<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full"
        data-boxed-layout="full">
        <div class="page-wrapper homepage" bg="@/core/assets/img/isologo-w.svg">
            <slot>
                <div class="bgloading" v-if="isLoading">
                    <Loader></Loader>
                </div>
                <div class="container" v-else>
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger text-center" role="alert" v-if="messageError != null">
                                {{ messageError }} <br><br>
                                <button class="btn btn-info btn-sm" @click.prevent="$router.push({ name: 'Login'})">Back Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <!-- <notifications group="noti" position="top center"></notifications> -->
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/LoaderProgress'
    export default {
        name: 'LoginSupport',
        components: {
            Loader,
        },
        data () {
            return {
                isLoading: false,
                messageError: null,
                userCredenctial: {
                    email: '',
                    phone: '',
                    password: '',
                    codeSMS: '',
                    typeU: 'client'
                },
            }
        },
        created () {
            console.log('login support')
            this.validLogin();
        },
        methods: {
            validLogin(){
                let t = this;
                t.isLoading = true;
                if(t.$route.params && t.$route.params.p && t.$route.params.c){
                    // let oauth_id = t.$route.params.oId;
                    // let resellerId = t.$route.params.rId;
                    // let accessToken = t.$route.params.aTk;
                    // if(t.isStringNotNull(oauth_id) === true){
                    //     console.log('is oauth_id', oauth_id)
                    //     localStorage.setItem('oauth_id', oauth_id)
                    // }
                    // console.log('is resellerId', t.isStringNotNull(resellerId))
                    // if(t.isStringNotNull(resellerId) === true){
                    //     localStorage.setItem('resellerId', resellerId)
                    // }
                    // if(t.isStringNotNull(accessToken) === true){
                    //     localStorage.setItem('token', accessToken)
                    // }
                    // t.validSesion()
                    if(t.isStringNotNull(t.$route.params.p) === true && t.isStringNotNull(t.$route.params.c) === true){
                        t.loginReseller();
                    }else {
                        console.log('back login', t.$route.params)
                        // t.$router.push({ name: 'Login'});
                    }
                } else {
                    console.log('back login', t.$route.params)
                    // t.$router.push({ name: 'Login'});
                }
            },
            validSesion(){
                let t = this;
                let p = {
                    url: 'r1/sessionResellerLogin',
                    data: {
                        oauth_id: localStorage.getItem('oauth_id'),
                        user_id: localStorage.getItem('resellerId'),
                    }
                };
                t.$store.dispatch('apiRest', p)
                .then(result => {
                    console.log(p.url, 'bien', result)
                    if(t.$store.getters['getLoggedIn']){
                        t.$router.push({ name: 'Dash'});
                    }
                }).catch((err) => {
                    console.log(p.url, 'Error', err)
                });
            },
            isStringNotNull(payload){
                return (typeof payload == 'string' && payload.length>0 && payload.toLowerCase().trim()!='null')
            },
            loginReseller() {
                let t = this;
                t.messageError = null;
                t.userCredenctial.password = t.$route.params.c
                t.userCredenctial.codeSMS = t.$route.params.c
                t.userCredenctial.phone = t.$route.params.p;
                t.userCredenctial.email= t.$route.params.p;
                let p = {
                    url: 'r1/loginReseller',
                    data: t.userCredenctial,
                };
                t.$store.dispatch('apiRest', p)
                .then(result => {
                    console.log(p.url, 'bien', result)
                    t.$store.commit('setLogin', result);
                    if (t.$store.getters['getLoggedIn']) {
                        window.location.href = window.location.origin;
                    } else {
                        t.messageError = result.msj;
                        t.userCredenctial.codeSMS = null;
                    }
                    t.isLoading = false;
                }).catch((err) => {
                    console.log(p.url, 'Error', err)
                    t.isLoading = false;
                    t.messageError = err;
                    t.userCredenctial.phone = null;
                    t.userCredenctial.codeSMS = null;
                });
            },
        },
        computed: {
        },
        watch: {
        }
    };
</script>
<style lang="scss" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .bgloading {
        padding-top: 200px;
        align-items: initial !important;
    }
    .container {
        max-width: 1000px;
        padding-top: 10px;
        padding-bottom: 50px;
        /*background: linear-gradient(to right, rgb(255 255 255 / 68%) 0%, rgb(82 94 255) 50%, rgb(72 88 253) 100%);
        border-radius: 10px;    
        border: 1px solid rgb(38 55 242 / 12%);
        box-shadow: 3px 3px 3px rgb(44 62 248 / 34%);*/
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        position: relative;
        /*background: linear-gradient(to right,#031099 0%,#0212eb 50%,#3243f8 100%);*/
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 0px;
    }
    .page-wrapper.homepage:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-image: url('../assets/img/isologo-blue.svg');
        background-position: center;
        background-repeat: repeat-x;
        background-size: 200px;
        opacity: 0.1;*/
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 20px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 100%;
        }
    }
    @media (max-width: 767px) {
        .container {
            padding-top: 20px;
            padding-bottom: 0px;
            height: 100vh;
            border-radius: 0px;
        }
        .page-wrapper.homepage{padding-top: 00px;}
        .page-wrapper.homepage:before{
            /*background-image: url('../assets/img/isologo-blue.svg');
            background-repeat: repeat-y;
            background-size: 200px;
            opacity: 0.1;*/
        }
    }
    .appLogin{
        position: relative;
        display: inline-flex;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin: 10px 25px;
        overflow: hidden;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 10px #ddd;
        &:hover{cursor: pointer;}
        .iconapp{
            width: 100%;
            margin: auto;
        }
        &.bsiteme{
            .iconapp{
                position: relative;
                scale: 1.6;
            }
        }
        &.billing{
            .iconapp{
                scale: 1.1;
                position: relative;
                left: 2px;
                top: 2px;
            }
        }

    }
</style>