<template>
    <div>
        <div class="modal fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="saveform()">
                        <div class="modal-header">
                            <h5 class="modal-title">Logo Organization:</h5>
                            <button type="button" class="close" @click="$emit('emitClose', 0)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row" v-if="isLoading">
                                <div class="col-12"><Loader></Loader></div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-12" style="text-align: center;" @click="$refs.logo.click()">
                                    <span v-if="f.logoCount==0" style="font-size: 220px;"><i class="fas fa-image "></i></span>
                                    <span v-show="f.logoCount!=0">
                                        <img v-bind:src="f.logo" id="img1" v-bind:class="['img-responsive']" style="width: 310px; height: auto;" />
                                    </span>
                                    <br>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="file" style="display: none" ref="logo" accept="image/*" @change="preloadLogo" />
                                            <button type="button" class="btn btn-dark" @click="$refs.logo.click()">
                                                <i class="fas fa-upload"></i> Upload
                                            </button>
                                        </div>
                                        <div class="col-md-2"></div>
                                        <div class="col-md-5">
                                            <button type="submit" class="btn btn-success" :disabled="btnSave">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="messageError!=null">
                                <div class="col-md-12">
                                    <div class="alert alert-danger">
                                        {{ messageError }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
			btnSave: false,
			f:{
				user_id:'',
				org_id:'',
				logo: null,
				validlogo: false,
				logoCount:0,
			},
            messageError: null,
            dataModal: null,
        };
    },
    created(){
        let t = this;
        t.isLoading = true
        t.dataModal = t.$store.getters['getDataModal'];
        console.log('dataModal', t.dataModal);
        if(t.dataModal.i && t.dataModal.i.user_id!=null && t.dataModal.i.user_id>0){
            t.f.user_id = t.dataModal.i.user_id;
            t.f.org_id = t.dataModal.i.id;
            t.f.logoCount = t.dataModal.i.logoCount
            t.f.org_id = t.dataModal.i.id
            t.btnSave = true;
            if(t.dataModal.i.logoCount == 0) {
                t.f.logo = t.dataModal.logoEmpty
            } else {
                t.f.logo = t.dataModal.i.logoRuta
            }
            t.isLoading = false;
        }
    },
    methods: {
        preloadLogo(event) {
            // maximo 50 megas
            let t = this;
            if (t.$refs.logo.size <= 52428800) {
                let file = "";
				t.f.logo = "";
				t.btnSave = false;
                file = event.target.files[0];
                t.f.logo = file;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function (e) {
                        t.f.logoCount=1;
                        document.querySelector("#img1").src = e.target.result;
                    };
                }else{
                    t.f.logoCount=0;
                    t.$refs.logo.value=null;
                }
            } else {
                t.$refs.logo.value=null;
                t.f.logoCount=0;
				alert('The file exceeds 50 megabytes')
            }
        },
        saveform() {
            let t = this;
            t.isLoading = true
            t.messageError = null;
			t.f.validlogo = true;
			t.btnSave = true;
			let formData = new FormData();
			formData.append("validlogo", t.f.validlogo);
			formData.append("logo", t.f.logo);
			formData.append("org_id", t.f.org_id);
            let p = {
                url: 'r2/subirlogo',
                data: t.f
            };
            t.$store.dispatch('apiRestFiles', p)
            .then((response) => {
                console.log(response)
                t.isLoading = false;
                t.btnSave = false;
                if(response.status){
                    t.$emit('emitClose', 1);
                }else{
                    t.messageError = response.msj;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false;
                t.btnSave = false;
                t.messageError = error.message;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    position: relative;
    text-align: left;
    margin-bottom: 0.5rem;
}
</style>