import { createApp } from 'vue'
import '@/assets/scss/global.scss';
import urlServer from '@/config/urlServer';
const host = urlServer;
const cnx = 'test'; // valores = produccion | test | dev
window.urlmaster = host.urlmaster[cnx];
window.urlReseller = host.urlReseller[cnx];
window.urlbilling = host.urlbilling[cnx];
window.urlweb = host.urlweb[cnx];
window.urlbSite = host.urlbSite[cnx];
import axios from 'axios';
import keyServer from '@/config/keyServer';
window.apiR = axios.create();
window.apiR.defaults.baseURL = window.urlReseller;
window.apiR.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
window.apiR.defaults.headers.common['App-Key'] = keyServer.key;
window.apiR.defaults.headers.common['App-Oauth-Id'] = localStorage.getItem('oauth_id') || 0;
window.apiR.defaults.headers.common['App-Reseller-Id'] = localStorage.getItem('resellerId') || 0;
window.apiR.defaults.headers.post['Content-Type'] = 'application/json';
// try {
//     const response = await window.apiR.get('/r1/prueba');
//     console.log(response);
// } catch (e) {
//     console.error(e);
// }
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'

createApp(App)
.use(router)
.use(store)
.mount('#app')
