<template>
  <div style="display:flex;">
    <router-link :to="{ name: 'Dash' }">
      <img class="logo" alt="Marketea" src="@/core/assets/img/isotipo-M-white.svg">
    </router-link>
    <ul class="menuTop">
      <li class="sidebar-item" v-for="(i, index) in menuDefaul" :key="index" v-bind:class="{ sub: i.type === 2 }">
        <router-link :to="i.ruta" class="sidebar-link waves-effect waves-dark">
          <!-- <i :class="i.icon" style="font-size: 20px"></i> -->
          <span class="hide-menu">{{ i.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

  data() {
    return {
      isLoading: false,
      show: false,
      right: true,
      hover: false,
      activar: false, // abrir lateral
      menuDefaul: [
        {
          name: "Accounts",
          ruta: { name: "Dash" },
          icon: "fas fa-lg fa-users",
          m_id: 2,
          type: 1,
        },
        {
          name: "Org.",
          ruta: { name: "OrgView" },
          icon: "fas fa-lg fa-building",
          m_id: 1,
          type: 1,
        },
        {
          name: "Smart Office",
          ruta: { name: "SMView" },
          icon: "fas fa-lg fa-file-invoice-dollar",
          m_id: 2,
          type: 1,
        },
        {
          name: "bSite",
          ruta: { name: "bSiteView" },
          icon: "fas fa-lg fa-chart-line",
          m_id: 2,
          type: 1,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.menuTop{
    margin-left: 20px;
}
.menuTop li a {
  font-size: 14px;
  color: #fff;
  padding: 15px 15px;
  width: auto;
  display: flex;
  line-height: 1;
}
.menuTop li a span.hide-menu{
  margin-left: 5px;
}
.menuTop li a.router-link-exact-active {
  font-weight: bold;
  background: #030d75;
}
</style>