<template>
    <div>
        <div class="modal fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="saveform()">
                        <div class="modal-header">
                            <h5 class="modal-title">Login Information:</h5>
                            <button type="button" class="close" @click="$emit('emitClose', 0)">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row" v-if="isLoading">
                                <div class="col-12"><Loader></Loader></div>
                            </div>
                            <div class="row" v-else>
                                <div class="form-group col-md-12">
                                    <label>Email: <small>(Required)</small></label>
                                    <input type="text" v-model="f.email" class="form-control" required/>
                                </div>
                                <div class="form-group col-md-12">
                                    <label>Phone: <small>(Optional)</small></label>
                                    <input type="text" v-model="f.phone" class="form-control"/>
                                </div>
                                <div class="form-group col-md-12">
                                    <label style="display: flex;justify-content: space-between;width: 140px;">
                                        Change Password:
                                        <input type="checkbox" v-model="changePass" class="form-control"
                                        style="height: 15px;line-height: 1;display: inline-block;width: 20px;" />
                                    </label>
                                    <input type="text" v-model="f.password" class="form-control" :disabled="(!changePass)" :required="changePass"/>
                                </div>
                            </div>
                            <div class="row" v-if="messageError!=null">
                                <div class="col-md-12">
                                    <div class="alert alert-danger">
                                        {{ messageError }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
export default {
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            changePass: false,
            f: {
                userID: 0,
                email: null,
                phone: null,
                password: null,
            },
            messageError: null,
            dataModal: null,
        };
    },
    created(){
        this.dataModal = this.$store.getters['getDataModal'];
        console.log('dataModal', this.dataModal);
        if(this.dataModal && this.dataModal.userID!=null && this.dataModal.userID>0){
            this.getInfo(this.dataModal.userID);
        }
    },
    methods: {
        getInfo(user_id) {
            let t = this;
            t.isLoading = true
            t.messageError = null;
            let p = {
                url: 'r2/accountInfo/'+user_id,
                data: t.f
            };
            t.$store.dispatch('apiRest', p)
            .then((response) => {
                console.log('accountInfo', response)
                t.isLoading = false;
                t.f.userID = response.user.userID;
                t.f.email = response.user.email;
                t.f.phone = response.user.phone;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false
                t.messageError = error.message;
            });
        },
        saveform() {
            let t = this;
            t.isLoading = true
            t.messageError = null;
            let p = {
                url: 'r2/editLoginInfo',
                data: t.f
            };
            t.$store.dispatch('apiRest', p)
            .then((response) => {
                console.log(response)
                t.isLoading = false;
                if(response.status){
                    t.$emit('emitClose', 1);
                }else{
                    t.messageError = response.msj;
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false
                t.messageError = error.message;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    position: relative;
    text-align: left;
    margin-bottom: 0.5rem;
}
</style>