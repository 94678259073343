<template>
  <div id="app-body">
    <router-view/>
  </div>
</template>
<script setup>
  localStorage.setItem('validLogin', 0)
</script>
<style>
</style>
