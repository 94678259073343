<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full"
        data-boxed-layout="full">
        <div class="page-wrapper homepage" bg="@/core/assets/img/isologo-w.svg">
            <slot>
                <div class="bgloading" v-if="isLoading">
                    <Loader></Loader>
                </div>
                <div class="container ">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12">
                            <infoApp></infoApp>
                        </div>
                        <div class="col-xs-12 col-sm-12 formloginhome">
                            <formLogin></formLogin>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <!-- <notifications group="noti" position="top center"></notifications> -->
    </div>
</template>
<script>
    import Loader from '@/core/components/shared/LoaderProgress'
    import infoApp from '@/core/components/Login/InfoApp'
    import formLogin from '@/core/components/Login/FormLogin'
    import { mapGetters } from 'vuex'
    export default {
        name: 'LoginLayout',
        components: {
            Loader,
            infoApp,
            formLogin,
        },
        data () {
            return {
                // urlPage: window.urlweb
            }
        },
        created () {
            localStorage.removeItem('disconnected');
            if(this.$store.getters['getLoggedIn']){
                this.$router.push({ name: 'Dash'});
            }
            else if(localStorage.getItem('validLogin')==1){
                this.isLoading = true;
            }else{
                this.isLoading = false;
                this.validSesion();
            }
        },
        methods: {
            validSesion(){
                let t = this;
                let p = {
                    url: 'r1/sessionResellerLogin',
                    data: {
                        oauth_id: localStorage.getItem('oauth_id'),
                        user_id: localStorage.getItem('resellerId'),
                    }
                };
                t.$store.dispatch('apiRest', p)
                .then(result => {
                    console.log(p.url, 'bien', result)
                    if(t.$store.getters['getLoggedIn']){
                        t.$router.push({ name: 'Dash'});
                    }
                }).catch((err) => {
                    console.log(p.url, 'Error', err)
                });
            },
            getPrivilegios(){
            },
            logout() {
            },
        },
        computed: {
            ...mapGetters({
                stateApi: 'getStateApi',
                loadingApi: 'getLoadingApi',
                loadingApiProgress: 'getLoadingApiProgress',
                apiResponse: 'getApiResponse',
                apiError: 'getApiError',
            }),
            isLoading: {
                get: function(){
                    return this.loadingApi;
                },
                set: function(v){
                    return v;
                },
            }
        },
        watch: {
            stateApi: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('stateApi', newVal);
                }
            },
            loadingApi: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('loadingApi', newVal);
                    this.isLoading = newVal;
                }
            },
            loadingApiProgress: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('loadingApiProgress', newVal);
                }
            },
            apiResponse: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('apiResponse', newVal);
                }
            },
            apiError: function(newVal, oldVal){
                if(newVal != oldVal){
                    // console.log('apiError', newVal);
                }
            },
        }
    };
</script>
<style lang="scss" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .bgloading {
        padding-top: 200px;
        align-items: initial !important;
    }
    .container {
        max-width: 1000px;
        padding-top: 10px;
        padding-bottom: 50px;
        /*background: linear-gradient(to right, rgb(255 255 255 / 68%) 0%, rgb(82 94 255) 50%, rgb(72 88 253) 100%);
        border-radius: 10px;    
        border: 1px solid rgb(38 55 242 / 12%);
        box-shadow: 3px 3px 3px rgb(44 62 248 / 34%);*/
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        position: relative;
        /*background: linear-gradient(to right,#031099 0%,#0212eb 50%,#3243f8 100%);*/
        height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 0px;
    }
    .page-wrapper.homepage:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-image: url('../assets/img/isologo-blue.svg');
        background-position: center;
        background-repeat: repeat-x;
        background-size: 200px;
        opacity: 0.1;*/
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 20px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 100%;
        }
    }
    @media (max-width: 767px) {
        .container {
            padding-top: 20px;
            padding-bottom: 0px;
            height: 100vh;
            border-radius: 0px;
        }
        .page-wrapper.homepage{padding-top: 00px;}
        .page-wrapper.homepage:before{
            /*background-image: url('../assets/img/isologo-blue.svg');
            background-repeat: repeat-y;
            background-size: 200px;
            opacity: 0.1;*/
        }
    }
    .appLogin{
        position: relative;
        display: inline-flex;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin: 10px 25px;
        overflow: hidden;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1px 10px #ddd;
        &:hover{cursor: pointer;}
        .iconapp{
            width: 100%;
            margin: auto;
        }
        &.bsiteme{
            .iconapp{
                position: relative;
                scale: 1.6;
            }
        }
        &.billing{
            .iconapp{
                scale: 1.1;
                position: relative;
                left: 2px;
                top: 2px;
            }
        }

    }
</style>