<template>
    <div class="menu-account">
        <dropdown-menu
            direction="left"
        >
            <template #trigger>
                <button class="btn-account">
                    <span :class="['UserAvatarDefault', (!loggedIn) ? 'offline' : 'online']">
						{{ dataUser.name[0] }}
				</span>
                {{ dataUser.company_name }}
            </button>
            </template>
            <template #header >Hi, {{ dataUser.name }} {{ dataUser.lastname }} </template>
            <template #body>
                <ul class="menu-item-account">
                    <li><a href="">Profiler</a></li>
                    <li><a href="">Security</a></li>
                </ul>
            </template>
            <template #footer>
                <a href="#" @click.prevent="logout()">
                    <span class="logout">Sign Out</span>
                </a>
            </template>
        </dropdown-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DropdownMenu from 'v-dropdown-menu'
export default {
    components: {
        'dropdown-menu': DropdownMenu,
    },
    created(){
        console.log('desconexion', this.$store.getters['getLoggedIn'])
        if(!this.$store.getters['getLoggedIn']){
            this.logout();
        }
    },
    methods:{
        logout(){
            let t = this;
                let p = {
                    url: 'r1/logout',
                    data: {
                        oauth_id: localStorage.getItem('oauth_id'),
                        user_id: localStorage.getItem('resellerId'),
                    }
                };
                t.$store.dispatch('apiRest', p)
                .then(result => {
                    console.log(p.url, 'bien', result)
                    if(t.$store.getters['getLoggedIn']){
                        t.$store.commit('setLogout');
                    }
                    t.$router.push({ name: 'Login'});
                }).catch((err) => {
                    console.log(p.url, 'Error', err)
                });
        },
    },
    computed: {
        ...mapGetters({
            dataUser: 'getDataUser',
            loggedIn: "getLoggedIn",
        }),
    },
    watch: {
        loggedIn: function(){
            if(!this.loggedIn){
                this.logout();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.menu-account{
    position: relative;
}
button.btn-account {
    width: auto;
    font-size: 14px;
    color: #fff;
    padding: 6px 15px;
    background: transparent;
    box-sizing: border-box;
    box-shadow: none;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
button.btn-account .UserAvatarDefault{
    margin-right: 10px;
}
button.btn-account .UserAvatarDefault.offline{
    background-color: #da3009;
}
button.btn-account:hover {
    background: #030d75;
    cursor: pointer;
}
ul.menu-item-account {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}

ul.menu-item-account li {
    margin: 0;
    padding: 5px 10px;
}

ul.menu-item-account li:hover {
    background: #ededed;
    cursor: pointer;
}
</style>
