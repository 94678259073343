<template>
    <div class="">
        <div class="header">
            <div class="block block1" style="height: 50px">
                <main-nav></main-nav>
            </div>
            <div class="block block2">
                <ul class="menuTop">
                    <li><menu-account></menu-account></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapGetters } from "vuex";
import '../../assets/css/headerStyles.scss'
import MainNav from "@/core/components/header/MainNav";
import MenuAccount from "@/core/components/header/MenuAccount";
export default {
    components: {
        'main-nav': MainNav,
        'menu-account': MenuAccount,
    },
    methods: {
    },
};
</script>
<style lang="scss" scoped>

</style>
