import { createStore } from 'vuex'
export default createStore({
  state: {
    loggedIn: false,
    typeStates: ['not_used', 'preparing', 'in_progress', 'completed', 'error'],
    stateApi: 'not_used',
    loadingApi: 0,
    loadingApiProgress: 0,
    response: null,
    error: null,
    dataUser: localStorage.getItem('dataUser'),
    dataModal: null,
  },
  getters: {
    getTypeStates(state){ return state.typeStates;},
    getStateApi(state){ return state.stateApi;},
    getLoadingApi(state){ return (state.loadingApi==1);},
    getLoadingApiProgress(state){ return state.loadingApiProgress;},
    getApiResponse(state){ return state.response;},
    getApiError(state){ return state.error;},
    getDataUser(state){ return JSON.parse(state.dataUser);},
    getLoggedIn(state){
      if(localStorage.getItem('token')!=null &&
      localStorage.getItem('oauth_id')!=null &&
      localStorage.getItem('resellerId')!=null &&
      localStorage.getItem('token_expires_at')!=null &&
      localStorage.getItem('dataUser')!=null){
        state.loggedIn = true;
      }else{state.loggedIn = false;}
      return state.loggedIn
    },
    getDataModal(state){ return state.dataModal;},
  },
  mutations: {
    setStateApi(state, payload){
      localStorage.setItem('stateApi', state.typeStates[payload]);
      state.stateApi = localStorage.getItem('stateApi');
    },
    setLoadingApi(state, payload){
      localStorage.setItem('loadingApi', payload);
      state.loadingApi = localStorage.getItem('loadingApi');
    },
    setLoadingApiProgress(state, payload){
      localStorage.setItem('loadingApiProgress', payload);
      state.loadingApiProgress = localStorage.getItem('loadingApiProgress');
    },
    onloadProgress(state, payload){
      if(payload < 100 && payload > state.loadingApiProgress){
        localStorage.setItem('loadingApiProgress', payload);
        state.loadingApiProgress = localStorage.getItem('loadingApiProgress');
        state.typeStates[2];
      }
    },
    respCompleted(state, payload){
      localStorage.setItem('loadingApi', 0);
      state.loadingApi = localStorage.getItem('loadingApi');
      localStorage.setItem('loadingApiProgress', payload.progress);
      state.loadingApiProgress = localStorage.getItem('loadingApiProgress');
      localStorage.setItem('stateApi', state.typeStates[payload.stateApi])
      state.stateApi=localStorage.getItem('stateApi');
      state.response = payload.r;
      state.error = payload.e;
      setTimeout(() => {
        localStorage.setItem('loadingApiProgress', 0);
        state.loadingApiProgress = localStorage.getItem('loadingApiProgress');
        localStorage.setItem('stateApi', state.typeStates[0])
        state.stateApi=localStorage.getItem('stateApi');
      }, 5000);
      console.log('respCompleted', payload)
      if(payload.r && payload.r.data.revoked!=undefined && payload.r.data.revoked==true){
        console.log('disconnected');
        localStorage.setItem('validLogin', 1);
        localStorage.removeItem('token');
        alert('Your session has expired, log in again');
        state.loggedIn = false;
      }
      if(payload.e && payload.e.code=="ERR_NETWORK"){
        alert(payload.e.message);
      }
    },
    setLogin(state, payload){
      if(payload.status){
        state.loggedIn = true;
        if(payload.accessToken!=null){
          localStorage.setItem('token', payload.accessToken)
        }
        if(payload.oauth!=null){
          localStorage.setItem('oauth_id', payload.oauth)
        }
        if(payload.user_id!=null){
          localStorage.setItem('resellerId', payload.user_id)
        }
        if(payload.d!=null){
          localStorage.setItem('token_expires_at', payload.d)
        }
        if(payload.rData!=null){
          localStorage.setItem('dataUser', JSON.stringify(payload.rData))
        }
      }
    },
    setLogout(state){
      localStorage.setItem('validLogin', 1);
      localStorage.removeItem('token');
      localStorage.removeItem('oauth_id');
      localStorage.removeItem('resellerId');
      localStorage.removeItem('token_expires_at');
      localStorage.removeItem('dataUser');
      state.loggedIn = false;
    },
    setDataModal(state, payload){ state.dataModal =payload;},
    getParameterByName(state, payload) {
      var name = payload;
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
      return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    isStringNotNull(state, payload){
      return (typeof payload == 'string' && payload.length>0 && payload.toLowerCase().trim()!='null')
    },
  },
  actions: {
    apiRest(context, payload){
      context.commit('setStateApi', 1);
      return new Promise((resolve, reject) => {
        context.commit('setLoadingApiProgress', 0);
        // eslint-disable-next-line no-debugger
        // debugger
        window.apiR.post(payload.url, payload.data, {
          onUploadProgress: (progressEvent) => {
              // console.log('onDownloadProgress', progressEvent)
              let scanPer = Math.floor((progressEvent.loaded*100 / progressEvent.total));
              context.commit('onloadProgress', scanPer);
          }
        })
        .then((response) => {
          // console.log(response);
          context.commit('respCompleted', {e: null, stateApi: 3, r : response,progress: 100});
          resolve(response.data);
        }).catch((error) => {
          // console.log(error);
          context.commit('respCompleted', {r : null, e: error, stateApi: 4,progress: 100});
          reject(error);
        });
      });
    },
    apiRestFiles(context, payload){
      context.commit('setStateApi', 1);
      return new Promise((resolve, reject) => {
        context.commit('setLoadingApiProgress', 0);
        // eslint-disable-next-line no-debugger
        // debugger
        window.apiR.post(payload.url, payload.data, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
              // console.log('onDownloadProgress', progressEvent)
              let scanPer = Math.floor((progressEvent.loaded*100 / progressEvent.total));
              context.commit('onloadProgress', scanPer);
          }
        })
        .then((response) => {
          // console.log(response);
          context.commit('respCompleted', {e: null, stateApi: 3, r : response,progress: 100});
          resolve(response.data);
        }).catch((error) => {
          // console.log(error);
          context.commit('respCompleted', {r : null, e: error, stateApi: 4,progress: 100});
          reject(error);
        });
      });
    },
    setParametersUrl(context){
      return new Promise((resolve) => {
        var oauth_id = context.commit('getParameterByName', 'oId');
        var resellerId = context.commit('getParameterByName', 'rId');
        var status  = false;
        if(context.commit('isStringNotNull', oauth_id) === true){
          console.log('is oauth_id', oauth_id)
          localStorage.setItem('oauth_id', oauth_id)
        }
        if(context.commit('isStringNotNull', resellerId) === true){
          console.log('is resellerId', resellerId)
          localStorage.setItem('resellerId', resellerId)
        }
        if(localStorage.getItem('oauth_id') && localStorage.getItem('resellerId')){status=true;}
        resolve(status);
      });
    },
  },
  modules: {
  }
})
