<template>
  <div class="contenedor-home">
    <div class="container">
      <div class="row">
        <div class="col-md-2 text-left" style="padding: 0rem 0.8rem">
          <button type="button" class="btn btn-success btn-login" @click="openCreateAccount">+ Add Account</button>
        </div>
        <div class="col-md-2" style="padding: 0rem 0.8rem">
          <div class="row alert alert-success box-total" style="padding: 0.3rem 0.8rem">
            <label class="text-dark col-md-6 tlabel" style="display: flex;align-items: center;">
              Accounts:
            </label>
            <div class="col-md-6 text-center" style="display: flex;align-items: center;">
              <span class="badge badge-light total_ipad">
                <h5 style="margin-bottom: 0;">{{ totalAccount }}</h5>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-2" style="padding: 0rem 0.8rem">
          <div class="row alert alert-info box-total" style="padding: 0.3rem 0.8rem">
            <label class="text-dark col-md-8 tlabel" style="display: flex;align-items: center;">
              Organizations:
            </label>
            <div class="col-md-4 text-center" style="display: flex;align-items: center;">
              <span class="badge badge-light total_ipad">
                <h5 style="margin-bottom: 0;">{{ totalOrgs }}</h5>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-left" style="padding: 0rem 0.8rem">
          <input type="text" class="form-control" v-model="TextSearch" placeholder="Search"
            style="border: 1px solid #c9c9ca;" />
        </div>
      </div>
      <div class="row">
        <div class="card-body tdynamic">
          <div class="content-table-tdynamic">
            <table class="table-tdynamic" id="tableID">
              <thead>
                <tr>
                  <th scope="col" class="colId">ID</th>
                  <th scope="col" class="billInf">Billing information</th>
                  <th scope="col" class="emailPhone">Login information</th>
                  <th scope="col" class="startDate">Start Date</th>
                  <th scope="col" class="lastLogin">Login Activity</th>
                  <th scope="col" class="colStatus">Status</th>
                  <th scope="col" class="colOrgs">Orgs</th>
                  <th scope="col" class="colActions">Actions</th>
                </tr>
              </thead>
              <tbody v-if="isLoading === true">
                <tr>
                  <td colspan="8" style="text-align: center">
                    <loader></loader>
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="result.length === 0">
                <tr>
                  <td colspan="8" style="text-align: center">
                    No record
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(i, index) in filtered" :key="index">
                  <td class="colId">{{ i.userID }}</td>
                  <td class="billInf" @click="openEditBillInfo(i)" style="cursor:pointer;">
                    <b>{{ i.businnes }}</b><br>
                    {{ i.name }} {{ i.lastname }}
                  </td>
                  <td class="emailPhone" @click="openEditlogin(i)" style="cursor:pointer;">
                    {{ i.email }} <br>
                    {{ i.phoneLogin }}
                  </td>
                  <td class="startDate">
                    {{ i.created_date }}<br>
                    {{ i.created_time }}
                  </td>
                  <td class="lastLogin">
                    {{ i.loginActivity_date }}<br>
                    {{ i.loginActivity_time }}
                  </td>
                  <td class="colStatus">
                    <div class="action_btn_status">
                      <!-- @click="activeStatus(index)" -->
                      <span v-bind:class="[
                        'statusSelected badge',
                        (i.status == 1) ? 'badge-success active' :
                          (i.status == 2) ? 'badge-dark inactive' :
                            (i.status == 3) ? 'badge-danger spam' :
                              (i.status == 4) ? 'badge-danger suspended' : '',
                      ]" style="cursor: pointer">
                        {{ i.nameStatus }}
                        <!-- <i class="fas fa-angle-down"></i> -->
                      </span>
                      <!-- <span class="status_list" v-if="statusActive == index">
                        <ul class="reset" style="padding-left: 0;">
                          <li v-for="(s, sindex) in statuslist" :key="sindex" @click="changeStatus(s.statusID, i.userID)">
                            <span v-bind:class="[
                              'badge',
                              (s.statusID == 1) ? 'badge-success active' :
                                (s.statusID == 2) ? 'badge-dark inactive' :
                                  (s.statusID == 3) ? 'badge-danger spam' :
                                    (s.statusID == 4) ? 'badge-danger suspended' : '',
                            ]">{{ s.nameStatus }}</span>
                          </li>
                        </ul>
                      </span> -->
                    </div>
                  </td>
                  <td class="colOrgs" @click="openOrgs(i)">
                    <span class="badge badge-pill badge-dark"> {{ i.orgs }}</span>
                  </td>
                  <td class="colActions">
                    <!--  -->
                    <span class="cdropdown">
                      <button><i class="fas fa-cog"></i></button>
                      <label>
                        <input type="checkbox" class="submenu" @click="checkSubmenu($event)" />
                        <ul>
                          <li @click="openEditBillInfo(i)">
                            Billing Info
                          </li>
                          <li @click="openEditlogin(i)">
                            Login Info
                          </li>
                          <li @click="openOrgs(i)">
                            Organizations
                          </li>
                        </ul>
                      </label>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <component :is="nameCompModal" v-if="isShowModal" @emitClose="getEmitcloseModal($event)"></component>
  </div>
</template>

<script>
import Loader from '@/core/components/shared/LoaderProgress'
import createAccount from '@/core/components/AccM/createAccount'
import EditBillInfo from '@/core/components/AccM/EditBillInfo'
import EditLoginInfo from '@/core/components/AccM/EditLoginInfo'
import OrgList from '@/core/components/AccM/OrgList'
export default {
  name: 'HomeView',
  components: {
    Loader,
    createAccount,
    EditBillInfo,
    EditLoginInfo,
    OrgList,
  },
  data() {
    return {
      isLoading: false,
      totalAccount: 0,
      totalOrgs: 0,
      totalsuspend: 0,
      result: [],
      f: {
        resellerId: localStorage.getItem('resellerId'),
      },
      statusActive: null,
      statuslist: [],
      activeModalOrgs: false,
      TextSearch: "",
      isShowModal: false,
      nameCompModal: null,
    }
  },
  created() {
    this.getAcount();
  },
  methods: {
    activeStatus(index) {
      if (this.statusActive == index) {
        this.statusActive = null;
      } else {
        this.statusActive = index;
      }
    },
    changeStatus(status, user_id) {
      console.log(status, user_id)
    },
    checkSubmenu(e) {
      let submenus = document.querySelectorAll('.' + e.target.classList[0]);
      submenus.forEach(function (i) {
        if (i != e.target) {
          i.checked = false;
        }
      });
    },
    getAcount() {
      let t = this;
      t.isLoading = true
      let p = {
        url: 'r2/getAccounts',
        data: t.f
      };
      t.$store.dispatch('apiRest', p)
        .then((response) => {
          console.log(response);
          if (response.status) {
            let u = response.r;
            let statuslist = response.statusList;
            let statusName = ['Active', 'Inactive', 'Spam', 'Suspended'];
            for (let i in u) {
              if (u[i].status == 1) { u[i].statusName = statusName[0]; }
              if (u[i].status == 2) { u[i].statusName = statusName[1]; }
              if (u[i].status == 3) { u[i].statusName = statusName[2]; }
              if (u[i].status == 4) {
                u[i].statusName = statusName[3];
                t.totalsuspend++;
              }
              t.totalOrgs += parseInt(u[i].orgs);
            }
            t.totalAccount = u.length;
            t.result = u;
            console.log(u);
            t.statuslist = statuslist;
          }
          t.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          t.isLoading = false
        });
    },
    closeModal() {
      this.isShowModal = false;
      this.nameCompModal = null;
      this.$store.commit('setDataModal', null);
    },
    showModal() {
      this.isShowModal = true;
    },
    getEmitcloseModal(value) {
      console.log('getEmitcloseModal', value);
      this.closeModal();
      if (value == 1) {
        this.getAcount();
      }
    },
    openCreateAccount() {
      this.nameCompModal = "createAccount";
      this.showModal()
    },
    openEditBillInfo(i) {
      this.$store.commit('setDataModal', i);
      this.nameCompModal = "EditBillInfo";
      this.showModal()
    },
    openEditlogin(i) {
      this.$store.commit('setDataModal', i);
      this.nameCompModal = "EditLoginInfo";
      this.showModal()
    },
    openOrgs(i){
      this.$store.commit('setDataModal', i);
      this.nameCompModal = "OrgList";
      this.showModal()
    },
  },
  computed: {
    filtered: function () {
      var t = this;
      if (t.TextSearch.length == 0) {
        return t.result;
      } else {
        return this.result.filter(function (item) {
          let fullname = item.name + ' ' + item.lastname
          let fullnameA = item.name + ' ' + item.businnes
          let fullnameB = item.lastname + ' ' + item.name
          let fullnameC = item.lastname + ' ' + item.businnes
          let fullnameD = item.businnes + ' ' + item.lastname
          let fullnameE = item.businnes + ' ' + item.name
          let fullnameF = item.name + ' ' + item.lastname + ' ' + item.businnes
          let fullnameG = item.lastname + ' ' + item.name + ' ' + item.businnes
          let fullnameH = item.businnes + ' ' + item.lastname + ' ' + item.name
          let fullnameI = item.businnes + ' ' + item.name + ' ' + item.lastname
          return (
            (item.name &&
              item.name
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.lastname &&
              item.lastname
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.businnes &&
              item.businnes
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullname &&
              fullname
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameA &&
              fullnameA
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameB &&
              fullnameB
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameC &&
              fullnameC
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameD &&
              fullnameD
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameE &&
              fullnameE
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameF &&
              fullnameF
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameG &&
              fullnameG
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameH &&
              fullnameH
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (fullnameI &&
              fullnameI
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.phoneLogin &&
              item.phoneLogin
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
            (item.email &&
              item.email
                .trim()
                .toLowerCase()
                .indexOf(t.TextSearch.toLowerCase().trim()) >= 0)
          );
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/tdynamic.css';

.tdynamic {
  position: relative;
  height: calc(100vh - 130px);
  color: #555;
  margin-bottom: 20px;
}

.tdynamic .content-table-tdynamic {
  top: 0px;
}

.table-tdynamic thead tr th {
  font-weight: bold;
  height: 29px;
}

.table-tdynamic thead tr th,
.table-tdynamic tbody tr td {
  padding: 1px 8px;
}

.table-tdynamic thead tr th.colId,
.table-tdynamic tbody tr td.colId {
  width: 80px;
}

.table-tdynamic thead tr th.billInf,
.table-tdynamic tbody tr td.billInf {
  text-align: left;
  width: 250px;
}

.table-tdynamic thead tr th.emailPhone,
.table-tdynamic tbody tr td.emailPhone {
  text-align: left;
  width: 150px;
}

.table-tdynamic thead tr th.startDate,
.table-tdynamic tbody tr td.startDate {
  text-align: left;
  width: 150px;
}

.table-tdynamic thead tr th.startDate,
.table-tdynamic tbody tr td.startDate {
  text-align: left;
  width: 120px;
}

.table-tdynamic thead tr th.lastLogin,
.table-tdynamic tbody tr td.lastLogin {
  text-align: left;
  width: 120px;
}

.table-tdynamic thead tr th.colOrgs,
.table-tdynamic tbody tr td.colOrgs {
  width: 80px;
}

.table-tdynamic thead tr th.colStatus,
.table-tdynamic tbody tr td.colStatus {
  width: 80px;
}

.table-tdynamic thead tr th.colActions,
.table-tdynamic tbody tr td.colActions {
  width: 80px;
}

.table-tdynamic tbody tr td.colOrgs {
  cursor: pointer;
}

.status_list {
  padding: 0px;
  position: absolute;
  left: 0;
  top: 25px;
  background-color: #fff;
  /* width: 120px; */
  font-size: 14px;
  z-index: 9;
}

.status_list>ul {
  margin-bottom: 0px;
  border: 1px solid #ccc;
}

.status_list>ul>li {
  display: block;
  position: relative;
  margin: 1px 0;
  padding: 5px;
}

.status_list>ul>li:hover {
  background-color: #efefef;
  cursor: pointer;
}

.action_btn_status {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
}

span.statusSelected {
  font-size: 12px;
}

span.statusSelected i {
  color: #000;
}
</style>
