<template>
    <div>
        <div id="main-wrapper">
            <header-reseller></header-reseller>
            <div class="page-wrapper" v-bind:class="{ sinmenu: sinmenu }" @click="ShowHiddenAside">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import NavBar from '@/core/components/header/NavBar'
export default {
    name: 'DefaultLayoutR',
    components: {
        'header-reseller': NavBar,
    },
    data() {
        return {
            show: false,
            accessAccount: false,
            sinmenu: true,
        }
    },
    created() {
        console.log('sinmenu', this.sinmenu)
    },
    methods: {
        ShowHiddenAside() {
            // this.$store.commit("core/reseller/ActiveLateral", true);
        },
    },
    computed: {
        // ...mapGetters('core/session', { access: 'loggedInResller' }),
        // ...mapGetters('core', { getHeader: 'getHeader' }),
        // ...mapGetters("core/reseller", ["lateral"]),
    },
    watch: {
        lateral: function () {
            this.sinmenu = this.lateral;
            console.log('sinmenu', this.sinmenu)
        },
    },
};
</script>
<style lang="css" scoped>
.page-wrapper {
    margin-left: 180px;
    margin-top: 50px;
    z-index: initial !important;
}

.page-wrapper.sinmenu {
    margin-left: 0px !important;
}

@media(max-width: 767px) {

    .page-wrapper,
    .page-wrapper.sinmenu {
        margin-left: 0px !important;
    }
}</style>
